import moment, { Moment } from 'moment-timezone';

interface IFormatNumberOptions {
  ignoreUnit?: boolean;
  locale?: string;
  comma?: string;
  dot?: string;
  revert?: boolean;
  unit?: string;
  precision?: number;
  pad?: boolean;
  withAbs?: boolean;
  withSpace?: boolean;
}

class Prototype {
  string = {
    removeAllSpaces: (target: string) => target?.replace(/\s/g, ''),
    normalizePhoneString: (
      phone: string = '',
      options?: {
        countryCode?: string;
        ignoreSpaces?: boolean;
        ignoreBrackets?: boolean;
      }
    ): string => {
      const {
        countryCode = '',
        ignoreSpaces = false,
        ignoreBrackets = true
      } = options || {};

      const mCode = this.string.removeAllSpaces(
        ignoreBrackets ? countryCode : `(${countryCode})`
      );

      const mPhone = ignoreSpaces ? this.string.removeAllSpaces(phone) : phone;

      return `${mCode}${mPhone}`;
    },
    normalizeFullAddress: (options?: {
      country?: any;
      city?: any;
      address?: string;
      state?: any;
      suburb?: any;
      postCode?: string;
    }) => {
      const { address, country, city, state, suburb, postCode } = options || {};
      const arr = [];
      if (address) {
        arr.push(address);
      }
      if (suburb?.name) {
        arr.push(suburb.name);
      }
      if (state?.name) {
        arr.push(state.name);
      }
      if (postCode) {
        arr.push(postCode);
      }
      if (city?.name) {
        arr.push(city.name);
      }
      if (country?.name) {
        arr.push(country.name);
      }
      return arr.join(', ');
    }
  };

  number = {
    round: (v: number | string = 0, precision: number = 2) => {
      if (precision === 0) {
        return +Math.round(+v);
      }

      const e = Math.pow(10, precision);
      return +(Math.round(+v * e) / e);
    },
    pad2Digits: (v: number | string = 0) => {
      const x = parseInt(v as string);
      if (x >= 0 && x < 10) {
        return `0${x}`;
      }
      return `${x}`;
    },
    formatNumber: (v: number | string = 0, options?: IFormatNumberOptions) => {
      const {
        comma = ',',
        dot = '.',
        ignoreUnit = true,
        unit = '$',
        precision = 0,
        pad = false,
        revert,
        withAbs = false,
        withSpace = false
      } = options || {};
      const pattern = ignoreUnit ? '' : `${unit}${withSpace ? ' ' : ''}`;
      const withComma = this.number
        .round(withAbs ? Math.abs(+v) : v, precision)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, comma);

      let withPad = withComma;
      if (pad) {
        const dotPos = withComma.indexOf(dot);
        if (dotPos === -1) {
          withPad += '.00';
        } else {
          const [p1, p2] = withPad.split(dot);
          withPad = p1 + '.' + p2.padEnd(precision, '0');
        }
      }

      return revert
        ? `${withPad} ${pattern}`.trim()
        : withAbs
          ? `(${pattern}${withPad})`
          : `${pattern}${withPad}`;
    },
    formatCurrency: (
      v: number | string = 0,
      options?: IFormatNumberOptions
    ) => {
      const {
        comma = ',',
        dot = '.',
        ignoreUnit = false,
        unit = '$',
        precision = 2,
        pad = true,
        withAbs = false,
        withSpace = false
      } = options || {};

      return this.number.formatNumber(v, {
        comma,
        dot,
        ignoreUnit,
        unit,
        precision,
        pad,
        withAbs,
        withSpace
      });
    }
  };

  date = {
    toMoment: (d?: string | Moment, f?: string) => (d ? moment(d, f) : null),

    toUtc: (d?: string) => (d ? moment.utc(d) : null),

    applyTz: (d?: string, tz?: string) =>
      d ? moment.tz(d.slice(0, 19), tz || moment.tz.guess()) : null,

    toTz: (d?: string, tz?: string) =>
      d
        ? d.charAt(d.length - 1) === 'Z'
          ? moment(moment.tz(d.slice(0, d.length - 1), 'Greenwich')).tz(
              tz || moment.tz.guess()
            )
          : moment(moment.tz(d, 'Greenwich')).tz(tz || moment.tz.guess())
        : null,

    toLocal: (d?: string) => (d ? moment.utc(d).local() : null),

    formatDB: (d?: string | Moment, isLocal: boolean = true) =>
      d
        ? (isLocal ? moment.utc(d).local() : moment(d)).format('YYYY-MM-DD')
        : null,

    formatD: (
      d?: string | Moment,
      defaultValue: any = null,
      isLocal: boolean = true
    ) =>
      d
        ? (isLocal ? moment.utc(d).local() : moment(d)).format('DD/MM/YYYY')
        : defaultValue,

    formatDT: (
      d?: string | Moment,
      defaultValue: any = null,
      isLocal: boolean = true
    ) =>
      d
        ? (isLocal ? moment.utc(d).local() : moment(d)).format(
            'DD/MM/YYYY HH:mm'
          )
        : defaultValue,

    formatT: (d?: string | Date | Moment, isLocal: boolean = true) =>
      d ? (isLocal ? moment.utc(d).local() : moment(d)).format('HH:mm') : null,

    minToHour: (m: number = 0) => {
      if (m > 60) {
        const hours = Math.floor(m / 60);
        const minutes = m % 60;
        return `${hours}h${minutes}m`;
      }
      return `0h${m}m`;
    },

    now: () => moment()
  };

  core = {
    isNullOrUndefined: (v: any) =>
      v === null || v === undefined || v === 'undefined'
  };
}

export default new Prototype();
