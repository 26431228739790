import constants, {
  ENDPOINTS,
  PAGE_SIZE_DEFAULT_MAX,
  ParentSource,
  QUERY_KEYS,
  TemplateBusinessType,
  UserDataGroup,
  WebTable,
  tableRef
} from '@constants';
import {
  IScheduleCalendarParams,
  IScheduleEditDocumentsParams,
  IScheduleEditDriverInstructionsParams,
  IScheduleTmpSetOverMassParams,
  IScheduleSwapTripParams
} from '@request-dto';
import APIManager from '@services';
import { UIUtils } from '@utils';
import { useHistory } from 'react-router-dom';

import {
  useCUDMutationEnhancer,
  useGenericMutationEnhancer,
  useMutationEnhancer,
  useQueryEnhancer
} from '../core';

export * from './tmp';

export const useFetchSchedule = (id?: number | string) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.schedule, id ? parseInt(id as string) : null],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.schedule(':id', { id })
      });

      return res.data;
    },
    enabled: !!id
  });
};

export const useFetchScheduleDriverList = (scheduleId?: string | number) => {
  return useQueryEnhancer<any[] | undefined>({
    queryKey: [QUERY_KEYS.scheduleDriverList, scheduleId],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.scheduleDriver(),
        body: {
          size: PAGE_SIZE_DEFAULT_MAX,
          includeReportStatus: true,
          scheduleId,
          excludeFields: ['scheduleDriverTrucks']
        }
      });

      return res.data?.data;
    },
    enabled: !!scheduleId
  });
};

export const useFetchScheduleCalendar = (mParams: IScheduleCalendarParams) => {
  return useQueryEnhancer<any[]>({
    queryKey: [QUERY_KEYS.scheduleCalendar, JSON.stringify(mParams)],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.schedule(),
        body: {
          size: PAGE_SIZE_DEFAULT_MAX,
          ...mParams
        }
      });

      return res.data?.data;
    },
    enabled: !!mParams.isFetchData && !!mParams.fromDate && !!mParams.toDate
  });
};

export const useFetchPreviousSchedule = (isAllocation?: boolean) => {
  const history = useHistory();

  return useMutationEnhancer<any, string>({
    mutationFn: async currentDate => {
      const res = await APIManager.request({
        url: ENDPOINTS.schedule('previous'),
        body: {
          currentDate
        }
      });

      return res.data;
    },
    onSuccess: data => {
      if (data) {
        history.replace(
          `/admin/${isAllocation ? 'allocation' : 'operation'}/${data.id}`
        );
      }
    }
  });
};

export const useFetchNextSchedule = (isAllocation?: boolean) => {
  const history = useHistory();

  return useMutationEnhancer<any, string>({
    mutationFn: async currentDate => {
      const res = await APIManager.request({
        url: ENDPOINTS.schedule('next'),
        body: {
          currentDate
        }
      });

      return res.data;
    },
    onSuccess: data => {
      if (data) {
        history.replace(
          `/admin/${isAllocation ? 'allocation' : 'operation'}/${data.id}`
        );
      }
    }
  });
};

export const useLockSchedule = () => {
  return useMutationEnhancer<boolean | undefined, string | number>({
    mutationFn: async scheduleId => {
      const res = await APIManager.request({
        url: ENDPOINTS.schedule('lock-all-driver'),
        method: 'POST',
        body: {
          scheduleId
        }
      });

      return res.success;
    },
    onSuccess: data => {
      if (data) {
        tableRef?.[WebTable.SCHEDULE_DRIVER]?.init?.();
      }
    }
  });
};

export const useLockScheduleDriver = () => {
  return useMutationEnhancer<
    any,
    { scheduleDriverId: number; isLock: boolean }
  >({
    mutationFn: async data => {
      const { scheduleDriverId, isLock } = data;
      const res = await APIManager.request({
        url: ENDPOINTS.schedule(isLock ? 'lock-driver' : 'unlock-driver'),
        method: 'POST',
        body: {
          scheduleDriverId
        }
      });

      return res.success;
    },
    onSuccess: data => {
      if (data) {
        tableRef?.[WebTable.SCHEDULE_DRIVER]?.init?.();
      }
    }
  });
};

export const useDeleteScheduleDriver = () => {
  return useMutationEnhancer<any, number>({
    mutationFn: async scheduleDriverId => {
      const res = await APIManager.request({
        url: ENDPOINTS.schedule('remove-driver/:id', { id: scheduleDriverId }),
        method: 'DELETE'
      });

      return res.success;
    },
    onSuccess: data => {
      if (data) {
        tableRef?.[WebTable.SCHEDULE_DRIVER]?.init?.();
      }
    }
  });
};

export const useChangeScheduleDriver = () => {
  return useGenericMutationEnhancer<{
    scheduleDriverId: number;
    driverId: number;
  }>({
    endPoint: ENDPOINTS.schedule('change-driver'),
    method: 'POST',
    webTable: WebTable.SCHEDULE_DRIVER
  });
};

export const useFetchScheduleContainer = (id?: number | string) => {
  return useQueryEnhancer<any>({
    queryKey: [
      QUERY_KEYS.scheduleContainer,
      id ? parseInt(id as string) : null
    ],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.scheduleTripContainer(':id', { id })
      });

      return res.data;
    },
    enabled: !!id
  });
};

export const useFetchTemplateListForSchedule = (routingTypes: string[]) => {
  return useQueryEnhancer<any[]>({
    queryKey: [QUERY_KEYS.templateList],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.template(),
        body: {
          source: ParentSource.Allocation,
          childSource: constants.SOURCE_TYPE.ALLOCATION_SCHEDULE_TRIP_CONTAINER,
          businessType: TemplateBusinessType.Document,
          declarationRoutingTypes: routingTypes,
          size: PAGE_SIZE_DEFAULT_MAX
        }
      });

      return res.data?.data;
    }
  });
};

export const useFetchContainerEdocList = (
  transportJobId: number,
  containerId: number
) => {
  return useQueryEnhancer<any[]>({
    queryKey: [QUERY_KEYS.containerEdocList, transportJobId, containerId],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.edoc(':source', {
          source: ParentSource.TransportJob
        }),
        body: {
          sourceId: transportJobId,
          source: ParentSource.TransportJob,
          dataGroup: UserDataGroup.Driver,
          childSourceId: containerId,
          childSource: constants.SOURCE_TYPE.JOB_CONTAINER,
          includeParent: true,
          size: PAGE_SIZE_DEFAULT_MAX
        }
      });

      return res.data?.data;
    },
    enabled: !!transportJobId && !!containerId
  });
};

export const useFetchScheduleTripContainerNoteList = (
  sourceId: number,
  containerId: number
) => {
  return useQueryEnhancer<any[]>({
    queryKey: [QUERY_KEYS.scheduleContainerNoteList, sourceId, containerId],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.note(':source', {
          source: ParentSource.Schedule
        }),
        body: {
          sourceId: sourceId,
          source: ParentSource.Schedule,
          childSourceId: containerId,
          childSource: constants.SOURCE_TYPE.SCHEDULE_TRIP_CONTAINER,
          size: 1
        }
      });

      return res.data?.data ?? [];
    },
    enabled: !!sourceId && !!containerId
  });
};

export const useScheduleEditDocuments = () => {
  return useGenericMutationEnhancer<IScheduleEditDocumentsParams>({
    endPoint: ENDPOINTS.scheduleTripContainer('update-document'),
    method: 'PUT',
    webTable: WebTable.SCHEDULE_DRIVER
  });
};

export const useFetchTemplateReportListForScheduleContainer = (id: number) => {
  return useQueryEnhancer<any[]>({
    queryKey: [QUERY_KEYS.templateReportList, id || null],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.scheduleTripContainer(':id/template-report', { id }),
        body: {
          size: PAGE_SIZE_DEFAULT_MAX
        }
      });

      return res.data?.data;
    },
    enabled: !!id
  });
};

export const useScheduleEditAdditionalDocuments = (id: number) => {
  return useGenericMutationEnhancer<{ id?: number; templateReport: any }[]>({
    endPoint: ENDPOINTS.scheduleTripContainer(':id/template-report', { id }),
    method: 'PUT',
    webTable: WebTable.SCHEDULE_DRIVER
  });
};

export const useScheduleEditDriverInstructions = () => {
  return useGenericMutationEnhancer<IScheduleEditDriverInstructionsParams>({
    endPoint: ENDPOINTS.scheduleTripContainer('update-driver-instruction'),
    method: 'PUT',
    webTable: WebTable.SCHEDULE_DRIVER
  });
};

export const useScheduleEditContacts = (id: number) => {
  return useMutationEnhancer<
    boolean | undefined,
    { id: number; contactIds: number[] }
  >({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: ENDPOINTS.container(':id/routing/update-contact', { id }),
        method: 'PUT',
        body: data,
        showToast: true
      });

      return res.success;
    },
    onSuccess: data => {
      if (data) {
        tableRef?.[WebTable.SCHEDULE_DRIVER]?.init?.();
      }
    }
  });
};

export const useScheduleEditOverMass = (id: number) => {
  return useGenericMutationEnhancer<IScheduleTmpSetOverMassParams>({
    endPoint: ENDPOINTS.scheduleTrip(':id/update-over-mass', { id }),
    method: 'PUT',
    webTable: WebTable.SCHEDULE_DRIVER
  });
};

export const useCUDScheduleContainer = () => {
  return useCUDMutationEnhancer({
    endPoint: ENDPOINTS.scheduleTripContainer(),
    webTable: WebTable.SCHEDULE_DRIVER
  });
};

export const useScheduleAddAnotherContainer = (id: number) => {
  return useMutationEnhancer<boolean | undefined, any>({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: ENDPOINTS.scheduleTripContainer(':id', {
          id
        }),
        method: 'PUT',
        body: data
      });
      if (res.success && res.data) {
        const edocRes = await APIManager.request({
          url: ENDPOINTS.edoc(':source', {
            source: ParentSource.TransportJob
          }),
          body: {
            sourceId: data.transportJobId,
            dataGroup: UserDataGroup.Driver,
            childSourceId: data.containerId,
            childSource: constants.SOURCE_TYPE.JOB_CONTAINER,
            includeParent: true,
            size: PAGE_SIZE_DEFAULT_MAX
          },
          showToast: false
        });
        if (!edocRes.error && (edocRes.data?.data ?? []).length > 0) {
          await APIManager.request({
            method: 'PUT',
            url: ENDPOINTS.scheduleTripContainer('update-document'),
            body: {
              id: res.data.id,
              jobEdocIds: edocRes.data.data.map((i: any) => i.id)
            },
            showToast: false
          });
        }
      }
      return res.success;
    },
    onSuccess: data => {
      if (data) {
        tableRef?.[WebTable.SCHEDULE_DRIVER]?.init();
        UIUtils.popup.dismiss();
      }
    }
  });
};

export const useCUDConnectionTrip = () => {
  const history = useHistory();

  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.scheduleTrip(),
    webTable: WebTable.SCHEDULE_DRIVER,
    returnedData: true,
    onSuccess: ({ data, variables, options }) => {
      if (
        data?.scheduleId &&
        options.method === 'POST' &&
        variables.scheduleDate
      ) {
        history.replace(`/admin/operation/${data.scheduleId}`);
      }
    }
  });
};

export const useFetchScheduleTrip = (id?: number | string) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.scheduleTrip, id ? parseInt(id as string) : null],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.scheduleTrip(':id', { id })
      });

      return res.data;
    },
    enabled: !!id
  });
};

export const useSwapScheduleTrip = () => {
  return useGenericMutationEnhancer<IScheduleSwapTripParams>({
    endPoint: ENDPOINTS.scheduleTrip('swap'),
    method: 'PUT',
    webTable: WebTable.SCHEDULE_DRIVER
  });
};

export const useFetchScheduleTrailerList = (scheduleDate: string) => {
  return useQueryEnhancer<any[]>({
    queryKey: [QUERY_KEYS.scheduleTrailerList, scheduleDate],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.schedule('view-schedule-trailer'),
        body: {
          scheduleDate
        }
      });
      return res.data;
    },
    enabled: !!scheduleDate
  });
};

export const useCUDSchedule = () => {
  const mutation = useGenericMutationEnhancer<any>({
    method: 'DELETE',
    endPoint: ENDPOINTS.schedule(':id'),
    webTable: WebTable.SCHEDULE
  });

  return { deleteMutation: { ...mutation, deleteLoading: mutation.isLoading } };
};
