import {
  InvoiceStatus,
  InvoiceStatusId,
  ArInvoiceType,
  SystemSettingCode,
  ArApType,
  SyncStatus,
  ApInvoiceType
} from '@constants';
import { useAccountingContext } from '@context/Accounting';
import { Prototype } from '@core';
import {
  useActionsButton,
  useAskBeforeLeave,
  useCUDInvoice,
  useCheckHasXero,
  useConnectXero,
  useFetchInvoice,
  useFetchSetting,
  useFetchXeroStatus,
  useFetchXeroTenantList,
  useResolverForm,
  useSyncXero,
  useInvoiceEffect
} from '@hooks';
import { ValidationUtils, XeroManager } from '@utils';
import { useInitValues } from '@utils/normalize';
import { isEmpty, omit } from 'lodash';
import React, { memo, useCallback, useMemo } from 'react';
import { Controller, FormProvider, useWatch } from 'react-hook-form';
import { toast } from 'react-toastify';
import trans from 'translation';
import {
  KButton,
  KChip,
  KColors,
  KContainer,
  KForm,
  KGrid,
  KInput,
  KLabel,
  KListItemBaseItemProps
} from 'uikit';
import * as yup from 'yup';

import AP from './Details.AP';
import Basic from './Details.Basic';
import Charges from './Details.Charges';
import { IFormData, useCancelInvoice, useDeleteInvoice } from './helpers';

const schema = yup.object().shape({
  invoiceDate: ValidationUtils.requiredDate()
});

const NOT_INVOICED_STATUS_IDS = [
  InvoiceStatusId.Draft,
  InvoiceStatusId.Wip,
  InvoiceStatusId.Cancelled
];

const InvoiceDetails = () => {
  const { data: invoiceProvider } = useFetchSetting(
    SystemSettingCode.InvoiceSyncType,
    false
  );

  const {
    invoiceId: _invoiceId,
    setInvoiceId,
    arApType: _arApType,
    clearInvoice,
    tab
  } = useAccountingContext();

  const { onRequestLeave } = useAskBeforeLeave();

  const onBack = useCallback(
    () => onRequestLeave(() => clearInvoice()),
    [clearInvoice, onRequestLeave]
  );

  const invoiceId = useMemo(
    () =>
      (!_invoiceId || !/^[1-9][0-9]*$/.test(`${_invoiceId}`)
        ? undefined
        : _invoiceId) as number | undefined,
    [_invoiceId]
  );

  const { data: item, isLoading } = useFetchInvoice(invoiceId);

  const { invoiceType, invoiceProgressId } = item || {};

  const arApType = useMemo(
    () => invoiceType?.split('_')?.[0] ?? _arApType,
    [_arApType, invoiceType]
  );

  const isAr = arApType === ArApType.Ar;

  const {
    createMutation: { mutate: mutateCreate },
    updateMutation: { mutate: mutateUpdate },
    modifyLoading
  } = useCUDInvoice();

  const { onAlert, deleteLoading } = useDeleteInvoice();

  const { onAlert: onAlertCancel, isLoading: cancelLoading } =
    useCancelInvoice();

  const { data: hasXero } = useCheckHasXero();

  const { data: isLoggedInXero, isLoading: xeroStatusLoading } =
    useFetchXeroStatus(hasXero);

  const { data: tenantList = [] } = useFetchXeroTenantList(isLoggedInXero);

  const { mutate: mutateConnect, isLoading: connectLoading } = useConnectXero();

  const { mutate: mutateSync, isLoading: syncLoading } = useSyncXero();

  const isEdit = !isEmpty(item);

  const isDraft = invoiceProgressId === InvoiceStatusId.Draft;
  const isWip = invoiceProgressId === InvoiceStatusId.Wip;
  const isInvoiced = invoiceProgressId === InvoiceStatusId.Invoiced;
  const isBeforeInvoiced = isDraft || isWip;

  const showPostInvoice =
    isBeforeInvoiced || (!item?.progressStatus?.isSystem && !item?.syncStatus);
  const isView = !(!isEdit || showPostInvoice);

  const { normalizeIvInitValues } = useInitValues();

  const methods = useResolverForm<IFormData>({
    schema,
    configs: {
      values: normalizeIvInitValues(item, isAr)
    }
  });

  const [charges, tenantId, client, invoiceDate, mInvoiceType] = useWatch({
    control: methods.control,
    name: ['charges', 'tenantId', 'client', 'invoiceDate', 'invoiceType']
  });

  const isCN = [
    ArInvoiceType.ArCreditNote,
    ApInvoiceType.ApCreditNote
  ].includes(mInvoiceType);

  const cb = useCallback(
    (dueDate?: any) => {
      methods.setValue('dueDate', dueDate);
    },
    [methods]
  );

  useInvoiceEffect({
    arApType,
    clientId: client?.id,
    invoiceDate,
    isEdit: !!invoiceId,
    cb
  });

  const subTotalAmount = useMemo(
    () =>
      Prototype.number.round(
        charges.reduce((acc, cur) => {
          return acc + cur.amountWithoutTax;
        }, 0)
      ),
    [charges]
  );

  const totalTaxAmount = useMemo(
    () =>
      isEdit && item?.invoiceProgressId !== InvoiceStatusId.Draft
        ? item.totalTaxValue ?? 0
        : Prototype.number.round(
            charges.reduce((acc, cur) => {
              return acc + (cur.taxableAmount ?? 0);
            }, 0)
          ),
    [charges, isEdit, item?.invoiceProgressId, item?.totalTaxValue]
  );

  const totalAmount = useMemo(
    () =>
      isEdit &&
      ![InvoiceStatusId.Draft, InvoiceStatusId.Wip].includes(
        item?.invoiceProgressId
      )
        ? item.totalAmount ?? 0
        : Prototype.number.round(
            charges.reduce((acc, cur) => {
              return acc + (cur.totalAmount ?? 0);
            }, 0)
          ),
    [charges, isEdit, item?.invoiceProgressId, item?.totalAmount]
  );

  const onFormValid = useCallback(
    (data: IFormData) => {
      const {
        charges: _charges,
        attachedEdoc,
        invoiceProgress,
        client: _client,
        invoiceDate: _invoiceDate,
        dueDate,
        ...rest
      } = data;

      if (
        !isAr &&
        ![InvoiceStatusId.Draft, InvoiceStatusId.Wip].includes(
          invoiceProgress.id
        ) &&
        !_client
      ) {
        methods.setError('client', {
          message: trans('validation.required')
        });
        return;
      }

      const mParams: any = {
        ...omit(item, [
          'invoiceContainerCharges',
          'progressStatus',
          'attachedEdoc',
          'createdAt',
          'createdBy',
          'createdUsername',
          'updatedAt',
          'updatedBy',
          'updatedUsername'
        ]),
        ...omit(rest, ['chargeParams']),
        attachedResourceId: attachedEdoc?.id,
        invoiceProgressId: invoiceProgress.id,
        clientId: _client?.id,
        invoiceContainerCharges: _charges.map(i => {
          const _rest = omit(i, [
            'companyTariff',
            'companyTariffLevel',
            'invoice',
            'tax',
            'createdAt',
            'createdBy',
            'createdUsername',
            'updatedAt',
            'updatedBy',
            'updatedUsername'
          ]);

          return {
            ..._rest,
            taxId: i.tax?.id
          };
        }),
        totalAmount,
        totalTaxValue: totalTaxAmount,
        subTotal: subTotalAmount,
        totalDue: totalAmount,
        invoiceDate: Prototype.date.formatDB(_invoiceDate),
        dueDate: Prototype.date.formatDB(dueDate)
      };

      if ((totalAmount < 0 && !isCN) || (totalAmount >= 0 && isCN)) {
        toast.error(trans('invoice_must_be_credit_note'));
        return;
      }

      const zeroAmountCharges = _charges.filter(
        i => i.totalAmount === 0 || i.amountWithoutTax === 0
      );
      if (zeroAmountCharges.length > 0) {
        toast.error(
          trans('invalid_invoice_charges', {
            charges: zeroAmountCharges
              .map(i => i.companyTariff?.code ?? '')
              .join(', ')
          })
        );
        return;
      }

      if (!!mParams.id) {
        mutateUpdate(mParams);
      } else {
        mutateCreate(mParams, {
          onSuccess: (_data: any) => !!_data?.id && setInvoiceId(_data.id)
        });
      }
    },
    [
      isAr,
      isCN,
      item,
      methods,
      mutateCreate,
      mutateUpdate,
      setInvoiceId,
      subTotalAmount,
      totalAmount,
      totalTaxAmount
    ]
  );

  const title = useMemo(() => {
    const arr = [!isAr ? trans('ap_invoices') : trans('ar_invoices')];
    if (!invoiceId) {
      arr.push(trans('new'));
    } else if (!isEdit) {
      arr.push(`${invoiceId}`);
    } else {
      arr.push(`${item?.code}`);
    }
    return arr.join(' > ');
  }, [invoiceId, isAr, isEdit, item?.code]);

  const statusContainer = useMemo(() => {
    return (
      <KContainer.View row alignItems marginL={'1.5rem'}>
        <KChip
          background={KColors.palette.blue.w100}
          label={Prototype.number.formatCurrency(totalAmount, {
            withAbs: totalAmount < 0
          })}
          brC={KColors.palette.blue.w100}
          color={isCN ? KColors.secondary.normal : KColors.blue.normal}
          typo="TextMdMedium"
          padding={'0.5rem'}
          marginR={'1.5rem'}
        />

        {item?.progressStatus && (
          <KChip
            background={'#DEFFFD'}
            label={item?.progressStatus?.name}
            brC={KColors.primary.normal}
            color={item?.progressStatus?.color}
            typo="TextMdMedium"
            padding={'0.5rem'}
            marginR={'1.5rem'}
          />
        )}

        {item?.syncStatus && (
          <KChip
            background={'#DEFFFD'}
            label={item?.syncStatus}
            brC={KColors.primary.normal}
            color={
              item?.syncStatus.toLowerCase() === SyncStatus.Success
                ? KColors.primary.normal
                : KColors.secondary.normal
            }
            typo="TextMdMedium"
            padding={'0.5rem'}
            marginR={'1.5rem'}
          />
        )}

        {/* <KLabel.Text typo="Text2xLgMedium">
          {trans('total')}{' '}
          {Prototype.number.formatCurrency(totalAmount, {
            withAbs: totalAmount < 0
          })}
        </KLabel.Text> */}
      </KContainer.View>
    );
  }, [isCN, item?.progressStatus, item?.syncStatus, totalAmount]);

  const actions = useMemo(() => {
    const _buttons: KListItemBaseItemProps[] = [];
    const hasPayment = (item?.numOfPaymentDetail ?? 0) > 0;

    if (!isLoading) {
      if (isEdit && !hasPayment) {
        if (showPostInvoice) {
          _buttons.push({
            title: trans('delete'),
            icon: 'Delete',
            onPress: () => onAlert({ id: item?.id }, () => clearInvoice())
          });
        }

        if (isInvoiced) {
          _buttons.push({
            title: trans('cancel'),
            icon: 'Close',
            onPress: () => onAlertCancel({ invoiceId: item?.id })
          });
        }
      }

      if (!isEdit || showPostInvoice) {
        _buttons.push({
          title: trans('post_invoice'),
          icon: 'Save',
          onPress: () => {
            methods.setValue('invoiceProgress', {
              id: InvoiceStatusId.Invoiced,
              name: InvoiceStatus.Invoiced
            });
            methods.handleSubmit(onFormValid)();
          }
        });
      }

      if (!isAr && (!isEdit || isBeforeInvoiced)) {
        _buttons.push({
          title: trans('save_wip'),
          icon: 'Save',
          onPress: () => {
            methods.setValue('invoiceProgress', {
              id: InvoiceStatusId.Wip,
              name: InvoiceStatus.Wip
            });
            methods.handleSubmit(onFormValid)();
          }
        });
      }

      if (!isEdit || isDraft) {
        _buttons.push({
          title: trans('save_draft'),
          icon: 'Save',
          onPress: methods.handleSubmit(onFormValid)
        });
      }
    }

    return _buttons;
  }, [
    clearInvoice,
    isAr,
    isBeforeInvoiced,
    isDraft,
    isEdit,
    isInvoiced,
    isLoading,
    item?.id,
    item?.numOfPaymentDetail,
    methods,
    onAlert,
    onAlertCancel,
    onFormValid,
    showPostInvoice
  ]);

  const actionsButton = useActionsButton({
    actions,
    disabled: isLoading || modifyLoading || deleteLoading || cancelLoading
  });

  const showXero = useMemo(
    () =>
      isEdit &&
      invoiceProvider &&
      !NOT_INVOICED_STATUS_IDS.includes(item?.invoiceProgressId) &&
      hasXero &&
      !xeroStatusLoading,
    [
      hasXero,
      invoiceProvider,
      isEdit,
      item?.invoiceProgressId,
      xeroStatusLoading
    ]
  );

  const onConnectXero = useCallback(() => {
    const returnUrl = XeroManager.prepareInvoiceLink(item?.id, tab);
    mutateConnect(returnUrl, {
      onSuccess: _data => (window.location.href = _data)
    });
  }, [item?.id, mutateConnect, tab]);

  const onSyncXero = useCallback(() => {
    const tenant = tenantList.find(i => i.tenantId === tenantId);
    const mParams: any = {
      contactName: client?.name,
      tenantId,
      syncId: item?.syncNumber,
      invoiceType: item?.invoiceType,
      cbData: {
        id: item?.id,
        syncOrgId: tenantId,
        syncOrgName: tenant.tenantName,
        syncDate: new Date().toISOString().slice(0, 10)
      }
    };

    const syncData = ![
      ArInvoiceType.ArCreditNote,
      ApInvoiceType.ApCreditNote
    ].includes(item?.invoiceType)
      ? XeroManager.prepareInvoiceData(item)
      : XeroManager.prepareCreditNoteData(item);

    if (syncData) {
      mParams.data = syncData;
      mutateSync(mParams);
    }
  }, [client?.name, item, mutateSync, tenantId, tenantList]);

  const xeroButton = useCallback(() => {
    const _buttons: any[] = [];
    if (isLoggedInXero) {
      _buttons.push({
        id: 'sync',
        title: trans('sync_to', { name: invoiceProvider }),
        isLoading: syncLoading,
        onPress: onSyncXero,
        disabled: !tenantId
      });
    } else {
      _buttons.push({
        id: 'connect',
        title: trans('login_to', { name: invoiceProvider }),
        isLoading: connectLoading,
        onPress: onConnectXero
      });
    }

    return _buttons.map(i => {
      const { id: _btnId, ...r } = i;
      return <KButton.Solid key={_btnId} {...r} />;
    });
  }, [
    connectLoading,
    invoiceProvider,
    isLoggedInXero,
    onConnectXero,
    onSyncXero,
    syncLoading,
    tenantId
  ]);

  return (
    <FormProvider {...methods}>
      <KForm onSubmit={methods.handleSubmit(onFormValid)}>
        <KContainer.Card
          marginT="0.25rem"
          marginB="3rem"
          isLoading={
            isLoading || modifyLoading || deleteLoading || cancelLoading
          }
          header={{
            icon: {
              icon: 'ArrowBack',
              onPress: onBack
              // size: 'md'
            },
            title,
            content: <>{statusContainer}</>,
            rightNode: showXero ? (
              <KContainer.View row alignItems>
                {isLoggedInXero && (
                  <KContainer.View width={150} marginR="0.75rem">
                    <Controller
                      name="tenantId"
                      control={methods.control}
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <KInput.TextField
                            {...field}
                            label={trans('organization')}
                            options={tenantList.map(i => ({
                              ...i,
                              key: i.tenantId,
                              label: i.tenantName
                            }))}
                            message={error?.message}
                          />
                        );
                      }}
                    />
                  </KContainer.View>
                )}

                {xeroButton()}
              </KContainer.View>
            ) : undefined
          }}
        >
          <KGrid.Container alignItems="stretch">
            <KGrid.Item xs={6}>
              <Basic
                invoiceId={invoiceId}
                totalAmount={totalAmount}
                isView={isView}
                isAr={isAr}
              />
            </KGrid.Item>

            <KGrid.Item xs={6}>
              <AP isView={isView} />
            </KGrid.Item>

            {/* <KGrid.Item xs={12}> */}
            <Charges isView={isView} invoiceId={invoiceId} isAr={isAr} />
            {/* </KGrid.Item> */}

            <KGrid.Item xs={8}></KGrid.Item>

            <KGrid.Item xs={4}>
              <KContainer.View
                row
                alignItems="flex-end"
                justifyContent="space-between"
                marginT="0.75rem"
              >
                <KLabel.Text typo="TextLgMedium" textTransform="uppercase">
                  {trans('sub_total')}:
                </KLabel.Text>

                <KLabel.Text typo="Text2xLgMedium">
                  {Prototype.number.formatCurrency(subTotalAmount, {
                    withAbs: subTotalAmount < 0
                  })}
                </KLabel.Text>
              </KContainer.View>

              <KContainer.View
                row
                alignItems="flex-end"
                justifyContent="space-between"
                marginT="0.75rem"
              >
                <KLabel.Text typo="TextLgMedium" textTransform="uppercase">
                  {trans('total_tax_amount')}:
                </KLabel.Text>

                <KLabel.Text typo="Text2xLgMedium">
                  {Prototype.number.formatCurrency(totalTaxAmount, {
                    withAbs: totalTaxAmount < 0
                  })}
                </KLabel.Text>
              </KContainer.View>

              <KContainer.View
                row
                alignItems="flex-end"
                justifyContent="space-between"
                marginT="0.75rem"
              >
                <KLabel.Text typo="Text4xLgMedium" textTransform="uppercase">
                  {trans('total_invoice')}:
                </KLabel.Text>

                <KLabel.Text typo="Text4xLgMedium">
                  {Prototype.number.formatCurrency(totalAmount, {
                    withAbs: totalAmount < 0
                  })}
                </KLabel.Text>
              </KContainer.View>
            </KGrid.Item>
          </KGrid.Container>
        </KContainer.Card>

        {item?.invoiceProgressId !== InvoiceStatusId.Cancelled && actionsButton}
      </KForm>
    </FormProvider>
  );
};

export default memo(InvoiceDetails);
