import { DATE_FORMAT_SHORT, ENDPOINTS, Status } from '@constants';
import APIManager from '@services';
import { TableUtils } from '@utils';
import { get } from 'lodash';

import { useMutationEnhancer } from './useRequestProcessor';

export const getCachedTableConfigs = () => {
  const cacheTableConfigs = localStorage.getItem('tableConfigs');
  return cacheTableConfigs ? JSON.parse(cacheTableConfigs) : undefined;
};

export const cacheColumnOrder = (name: string, columnOrder: number[]) => {
  let tableConfigs = getCachedTableConfigs();

  const result = {
    ...(tableConfigs || {}),
    [name]: {
      ...(tableConfigs?.[name] || {}),
      columnOrder
    }
  };
  localStorage.setItem('tableConfigs', JSON.stringify(result));
};

export const cacheViewColumns = (
  name: string,
  columnName: string,
  action: string
) => {
  let tableConfigs = getCachedTableConfigs();

  const result = {
    ...(tableConfigs || {}),
    [name]: {
      ...(tableConfigs?.[name] || {}),
      viewColumns: {
        ...(tableConfigs?.[name]?.viewColumns || {}),
        [columnName]: action === 'remove' ? true : undefined
      }
    }
  };

  localStorage.setItem('tableConfigs', JSON.stringify(result));
};

export const getColumnOrderFromCache = (
  name: string,
  length: number
): number[] | undefined => {
  const tableConfigs = getCachedTableConfigs();
  if (!tableConfigs || !tableConfigs[name]) {
    return undefined;
  }

  const { columnOrder } = tableConfigs[name];
  if (columnOrder?.length === length) {
    return columnOrder;
  } else {
    // reset cache if add or delete in columns
    let newColumnOrder = [];
    for (let i = 0; i < length; i++) {
      newColumnOrder.push(i);
    }
    cacheColumnOrder(name, newColumnOrder);
    return newColumnOrder;
  }
};

export const getViewColumnsFromCache = (name: string) => {
  const tableConfigs = getCachedTableConfigs();
  if (!tableConfigs || !tableConfigs[name]) {
    return undefined;
  }

  return tableConfigs[name]?.viewColumns;
};

export const reorderColumnsBasedOnCache = (
  name: string,
  columns: any[]
): any[] => {
  // const columnOrder = getColumnOrderFromCache(name, columns.length);
  const viewColumns = getViewColumnsFromCache(name);

  if (viewColumns) {
    columns = columns.map(i => {
      if (viewColumns[i.name]) {
        i.options = { ...(i.options || {}), display: false };
      } else {
        i.options = { ...(i.options || {}), display: true };
      }
      return i;
    });
  }

  return columns;

  // if (columnOrder?.length === columns.length) {
  //   return columnOrder.map(i => columns[i]);
  // } else {
  //   return columns;
  // }
};

export const normalizeFinalColumns = (
  columns: any[],
  mappedFields?: {
    [key: string]: string;
  }
) => {
  return columns.map(i => ({
    ...i,
    mappedName: get(mappedFields, i.name, i.name),
    options: ['createdAt', 'updatedAt'].includes(i.name)
      ? { ...TableUtils.options.date(), ...i.options }
      : ['contractStart', 'contractEnd'].includes(i.name)
        ? { ...TableUtils.options.date(DATE_FORMAT_SHORT), ...i.options }
        : ['createdUsername', 'updatedUsername'].includes(i.name)
          ? { ...TableUtils.options.baseXLg, ...i.options }
          : ['remark', 'description'].includes(i.name)
            ? { ...TableUtils.options.withEllipsis, ...i.options }
            : { ...TableUtils.options.base, ...i.options },
    allowedToSort: i.options?.sort ?? true
  }));
};

export const useFetchFilter = () => {
  return useMutationEnhancer<any[], string>({
    mutationFn: async name => {
      const res = await APIManager.request({
        url: ENDPOINTS.advanceSearch(),
        body: {
          tableName: name,
          status: Status.Active
        }
      });

      return res.data ?? [];
    }
  });
};
