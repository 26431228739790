import {
  ArApType,
  PaymentStatusId,
  PaymentType,
  ResourceType,
  generateOptions
} from '@constants';
import { Prototype } from '@core';
import {
  useCUDPayment,
  useFetchBankAccountList,
  useFetchPaymentInvoiceList,
  useResolverForm,
  useUploadFile
} from '@hooks';
import { RequestUtils, ValidationUtils } from '@utils';
import { isEmpty, omit } from 'lodash';
import React, { memo, useCallback, useMemo } from 'react';
import { Controller, FormProvider, useWatch } from 'react-hook-form';
import { toast } from 'react-toastify';
import trans from 'translation';
import {
  KButton,
  KColors,
  KContainer,
  KForm,
  KGrid,
  KInput,
  KLabel,
  KPicker,
  useDropzone
} from 'uikit';
import * as yup from 'yup';

import Invoices from './Form.New.Invoices2';
import { IFormData } from './helpers';

interface IProps {
  clientId: number;
  arApType: ArApType;
}

const schema = yup.object().shape({
  paymentDate: ValidationUtils.requiredDate(),
  totalAmount: ValidationUtils.requiredNum()
});

const onGenInvoiceList = (data?: any[]) => {
  return (data ?? []).reduce<any[]>((acc, cur) => {
    if (!acc.some(e => e.invoiceId === cur.id)) {
      acc.push({
        invoice: cur,
        invoiceId: cur.id,
        invoiceAmount: cur.totalAmount,
        totalAmount: 0,
        totalDue: cur.totalDue,
        totalPaid: 0,
        checked: false
      });
    }

    return acc;
  }, []);
};

const FormNew = ({ clientId, arApType }: IProps) => {
  const { data: bankAccountList } = useFetchBankAccountList(arApType);

  const isAr = ArApType.Ar === arApType;

  const { data: invoiceList, isLoading: invoiceLoading } =
    useFetchPaymentInvoiceList(clientId, isAr);

  const {
    createMutation: { mutate, isLoading }
  } = useCUDPayment();

  const hasBankAccount = !isEmpty(bankAccountList);

  const methods = useResolverForm<IFormData>({
    schema,
    configs: {
      values: {
        paymentDate: null,
        bankAccount: undefined,
        referenceNumber: '',
        totalAmount: 0,
        paymentType: PaymentType.DirectDebit,
        accountNumber: '',
        accountName: '',
        bankBranch: '',
        bankCode: '',
        note: '',
        attachedFile: null,
        invoices: onGenInvoiceList(invoiceList)
      }
    }
  });

  const [totalAmount, invoices] = useWatch({
    control: methods.control,
    name: ['totalAmount', 'invoices']
  });

  const invoiceTotalAmount = useMemo(() => {
    return invoices.reduce<number>((acc, cur) => {
      if (cur.checked) {
        acc += +cur.totalAmount || 0;
      }
      return acc;
    }, 0);
  }, [invoices]);

  const outOfBalance = useMemo(() => {
    return Math.round(((+totalAmount || 0) - invoiceTotalAmount) * 100) / 100;
  }, [invoiceTotalAmount, totalAmount]);

  const { mutateAsync: mutateFile } = useUploadFile();

  const onChangeFile = useCallback(
    async (e: any) => {
      if (e.target.files && !isEmpty(e.target.files)) {
        const v = e.target.files[0];
        const formData = new FormData();
        formData.append('file', v);
        formData.append('resourceType', ResourceType.Edoc);

        const res = await mutateFile(formData);
        if (res) {
          methods.setValue('attachedFile', res);
        }
      }
    },
    [methods, mutateFile]
  );

  const handleDropFile = useCallback(
    (files: any[]) => {
      onChangeFile({ target: { files } });
    },
    [onChangeFile]
  );

  const { getRootProps } = useDropzone({
    onDrop: handleDropFile
  });

  const onFormValid = useCallback(
    (data: IFormData) => {
      const { attachedFile, invoices: invoiceDetails, ...rest } = data;
      const mParams = {
        clientId,
        accountingType: arApType,
        paymentProgressId: PaymentStatusId.Success,
        attachedResourceId: attachedFile?.id,
        totalReceived: rest.totalAmount,
        totalApplied: rest.totalAmount,
        outOfBalance: 0,
        ...RequestUtils.normalizeData({
          data: rest,
          dateFields: ['paymentDate']
        }),
        paymentDetails: invoiceDetails
          .filter(i => i.checked && Number(i.totalAmount) !== 0)
          .map(i => {
            const r = omit(i, ['checked', 'invoice']);
            return {
              ...r,
              totalAmount: Prototype.number.round(r.totalAmount),
              totalPaid:
                Prototype.number.round(r.invoiceAmount) -
                Prototype.number.round(r.totalDue) +
                Prototype.number.round(r.totalAmount)
            };
          })
      };

      if (outOfBalance !== 0) {
        toast.error(trans('out_of_balance_not_valid'));
        return;
      }

      if (mParams.paymentDetails.some(e => e.totalAmount > e.totalDue)) {
        toast.error(trans('payment_amount_not_valid'));
        return;
      }

      mutate(mParams);
    },
    [arApType, clientId, mutate, outOfBalance]
  );

  const invoiceDataList = useMemo(() => {
    return onGenInvoiceList(invoiceList);
  }, [invoiceList]);

  const onEndEditing = useCallback(
    (data: any) => {
      const index = invoices.findIndex(i => i.invoiceId === data.invoiceId);
      methods.setValue(`invoices.${index}`, data);
    },
    [invoices, methods]
  );

  return (
    <FormProvider {...methods}>
      <KForm onSubmit={methods.handleSubmit(onFormValid)}>
        <KGrid.Container {...getRootProps()}>
          <KGrid.Item xs={3}>
            <Controller
              name="paymentDate"
              control={methods.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <KPicker.Date
                    {...field}
                    label={trans('payment_date')}
                    required
                    message={error?.message}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={3}>
            <Controller
              name="referenceNumber"
              control={methods.control}
              render={({ field }) => {
                return (
                  <KInput.TextField
                    {...field}
                    label={trans('reference_number')}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={3}>
            <Controller
              name="totalAmount"
              control={methods.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <KInput.Currency
                    {...field}
                    label={trans('total_amount')}
                    required
                    message={error?.message}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={3}>
            <Controller
              name="paymentType"
              control={methods.control}
              render={({ field }) => {
                return (
                  <KInput.TextField
                    {...field}
                    label={trans('payment_type')}
                    options={generateOptions(PaymentType)}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={3}>
            <KContainer.RenderWhen>
              <KContainer.RenderWhen.If isTrue={hasBankAccount}>
                <Controller
                  name="bankAccount"
                  control={methods.control}
                  render={({ field }) => {
                    return (
                      <KInput.Autocomplete
                        {...field}
                        label={trans('account_number')}
                        options={bankAccountList}
                        onChange={(v: any) => {
                          methods.setValue('bankAccount', v);
                          methods.setValue('accountName', v?.accountName ?? '');
                          methods.setValue(
                            'accountNumber',
                            v?.accountNumber ?? ''
                          );
                          methods.setValue('bankBranch', v?.bankBranch ?? '');
                          methods.setValue('bankCode', v?.bankName ?? '');
                        }}
                        getOptionLabel={(o: any) => o?.accountNumber ?? ''}
                      />
                    );
                  }}
                />
              </KContainer.RenderWhen.If>

              <KContainer.RenderWhen.If isTrue>
                <Controller
                  name="accountNumber"
                  control={methods.control}
                  render={({ field }) => {
                    return (
                      <KInput.TextField
                        {...field}
                        label={trans('account_number')}
                      />
                    );
                  }}
                />
              </KContainer.RenderWhen.If>
            </KContainer.RenderWhen>
          </KGrid.Item>

          <KGrid.Item xs={3}>
            <Controller
              name="accountName"
              control={methods.control}
              render={({ field }) => {
                return (
                  <KInput.TextField
                    {...field}
                    label={trans('account_name')}
                    disabled={hasBankAccount}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={3}>
            <Controller
              name="bankCode"
              control={methods.control}
              render={({ field }) => {
                return (
                  <KInput.TextField
                    {...field}
                    label={trans('bank_name')}
                    disabled={hasBankAccount}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={3}>
            <Controller
              name="bankBranch"
              control={methods.control}
              render={({ field }) => {
                return (
                  <KInput.TextField
                    {...field}
                    label={trans('bank_branch')}
                    disabled={hasBankAccount}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={3}>
            <Controller
              name="note"
              control={methods.control}
              render={({ field }) => {
                return <KInput.TextField {...field} label={trans('note')} />;
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={3}>
            <Controller
              name="attachedFile"
              control={methods.control}
              render={({ field }) => {
                return (
                  <KInput.File
                    {...field}
                    label={trans('attached_file')}
                    accept="*"
                    value={field.value?.fileName ?? ''}
                    onChange={onChangeFile}
                    onPress={() => {
                      if (field.value?.url) {
                        window.open(field.value.url, '_blank');
                      }
                    }}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={12}>
            <KContainer.View
              row
              alignItems
              justifyContent="space-between"
              background="#DAFCFA"
              paddingV="0.75rem"
              paddingH="1rem"
              brW={1}
              brC={KColors.primary.normal}
            >
              <KLabel.Text typo="TextMdMedium" textTransform="uppercase">
                {trans(isAr ? 'total_received' : 'total_paid')}:{' '}
                {Prototype.number.formatCurrency(totalAmount, {
                  withAbs: totalAmount < 0
                })}
              </KLabel.Text>

              <KLabel.Text typo="TextMdMedium" textTransform="uppercase">
                {trans('total_applied')}:{' '}
                {Prototype.number.formatCurrency(invoiceTotalAmount, {
                  withAbs: invoiceTotalAmount < 0
                })}
              </KLabel.Text>

              <KLabel.Text
                typo="TextMdMedium"
                textTransform="uppercase"
                color={outOfBalance < 0 ? KColors.secondary.normal : undefined}
              >
                {trans('out_of_balance')}:{' '}
                {Prototype.number.formatCurrency(outOfBalance, {
                  withAbs: outOfBalance < 0
                })}
              </KLabel.Text>
            </KContainer.View>
          </KGrid.Item>

          <KGrid.Item xs={12}>
            <Invoices
              data={invoiceDataList}
              extraLoading={invoiceLoading}
              onEndEditing={onEndEditing}
            />
          </KGrid.Item>

          <KGrid.Item xs={12} style={{ textAlign: 'right' }}>
            <KButton.Solid
              type="submit"
              isLoading={isLoading}
              title={trans('save')}
            />
          </KGrid.Item>
        </KGrid.Container>
      </KForm>
    </FormProvider>
  );
};

export default memo(FormNew);
