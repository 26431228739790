import { useAlertMutationEnhancer, useCUDNote } from '@hooks';
import loadable from '@loadable/component';
import { IItemProps, INoteOptions } from '@ui';
import { UIUtils } from '@utils';
import React from 'react';
import trans from 'translation';

const Form = loadable(() => import('./Form'));

export const onShowNote = (options: INoteOptions & IItemProps) => {
  return UIUtils.popup.open({
    title: trans('note'),
    maxWidth: 'md',
    content: () => <Form {...options} />
  });
};

export const useDeleteNote = (options: INoteOptions) => {
  const { deleteMutation } = useCUDNote(options);

  return useAlertMutationEnhancer({ mutation: deleteMutation });
};
