import Filter from '@containers/DataTable/Filter';
import { IAdvanceSearch, ISearchCondition } from '@dto';
import { UIUtils } from '@utils';
import React, { memo, useCallback } from 'react';
import trans from 'translation';
import { KButton, KContainer, KInput } from 'uikit';

interface IProps {
  id: string;
  keyword?: string;

  advanceSearch?: IAdvanceSearch[];
  searchConditions?: ISearchCondition[];

  leftNode?: JSX.Element;

  showSearch?: boolean;
  showFilter?: boolean;

  onAdd?: () => void;
  onSearch?: (keyword: string) => void;
  setSearchConditions?: (searchCondition: any[]) => void;
}

const LeftActions = (props: IProps) => {
  const {
    id,

    leftNode,
    showSearch,
    showFilter,
    keyword,
    advanceSearch,
    searchConditions,

    onAdd,
    onSearch,
    setSearchConditions
  } = props;

  const ref = React.createRef<HTMLButtonElement>();

  const onSearchWrapper = useCallback(
    (e: any) => {
      onSearch?.(e?.target?.value ?? '');
    },
    [onSearch]
  );

  const onFilter = useCallback(() => {
    UIUtils.popper.dismiss();
    setTimeout(
      () =>
        UIUtils.popper.open({
          anchorEl: ref.current,
          placement: 'bottom-start',
          content: dismiss => (
            <Filter
              dismiss={dismiss}
              advanceSearch={advanceSearch || []}
              searchConditions={searchConditions || []}
              onSubmit={setSearchConditions}
            />
          ),
          maxW: 700,
          style: { zIndex: 5 }
        }),
      100
    );
  }, [advanceSearch, ref, searchConditions, setSearchConditions]);

  const count = (searchConditions ?? []).length;

  return (
    <KContainer.View row alignItems gap="0.5rem">
      {onAdd && (
        <KButton.Icon
          key={`ag-table-add-${id}`}
          tight
          kind="primary"
          icon="AddBox"
          size="xlg"
          onPress={onAdd}
        />
      )}

      {showSearch && (
        <KInput.Search
          key={`ag-table-search-${id}`}
          name="agSearch"
          placeholder={trans('search_placeholder')}
          value={keyword}
          onChange={onSearchWrapper}
          minW={200}
          fullWidth={false}
        />
      )}

      {showFilter && (
        <KButton.Outline
          key={`filter-${id}`}
          ref={ref}
          icon="FilterList"
          aria-haspopup="true"
          onPress={onFilter}
          title={trans('filter') + (count > 0 ? ` (${count})` : '')}
          kind={count > 0 ? 'primary' : 'normal'}
        />
      )}

      {leftNode}
    </KContainer.View>
  );
};

export default memo(LeftActions);
