import { UIMenuCode } from '@constants';
import loadable from '@loadable/component';
import Accounting from '@pages/Accounting';
import ApiIntegration from '@pages/ApiIntegration';
import Billing from '@pages/Billing/Billing';
import Clients from '@pages/Client';
import ClientRates from '@pages/ClientRate';
import ClientTariff from '@pages/ClientTariff';
import CompanyTariff from '@pages/CompanyTariff';
import Dashboard from '@pages/Dashboard';
import EmailLogs from '@pages/EmailLogs';
import FaultyReport from '@pages/FaultyReport';
import Maintenance from '@pages/Maintenance';
import Quotation from '@pages/Quotation';
import ReportOperations from '@pages/Report/Operations';
import Roles from '@pages/Roles';
import Schedule from '@pages/Schedule';
import SystemLogs from '@pages/SystemLogs';
import TransportJobs from '@pages/TransportJob';
import { IAdminRoute } from '@ui';
import pMinDelay from 'p-min-delay';

const CompanyProfile = loadable(() =>
  pMinDelay(import('@pages/CompanyProfile'), 200)
);

const ScheduleDetails = loadable(() =>
  pMinDelay(import('@pages/Schedule/Layout'), 200)
);

const Library = loadable(() => pMinDelay(import('@pages/Library'), 200));

const TransportJobDetails = loadable(() =>
  pMinDelay(import('@pages/TransportJob/DetailsLayout'), 200)
);

const ClientDetails = loadable(() =>
  pMinDelay(import('@pages/Client/DetailsLayout'), 200)
);

const StatementDetails = loadable(() =>
  pMinDelay(import('@pages/Statement/DetailsLayout'), 200)
);

const Staff = loadable(() => pMinDelay(import('@pages/Staff'), 200));
const StaffDetails = loadable(() =>
  pMinDelay(import('@pages/Staff/DetailsLayout'), 200)
);

const Users = loadable(() => pMinDelay(import('@pages/User'), 200));
const UserDetails = loadable(() =>
  pMinDelay(import('@pages/User/DetailsLayout'), 200)
);

const Equipment = loadable(() => pMinDelay(import('@pages/Equipment'), 200));
const EquipmentDetails = loadable(() =>
  pMinDelay(import('@pages/Equipment/DetailsLayout'), 200)
);

const QuotationDetails = loadable(() =>
  pMinDelay(import('@pages/Quotation/DetailsLayout'), 200)
);

const ClientRateDetails = loadable(() =>
  pMinDelay(import('@pages/ClientRate/DetailsLayout'), 200)
);

const CompanyTariffDetails = loadable(() =>
  pMinDelay(import('@pages/CompanyTariff/DetailsLayout'), 200)
);

const ClientTariffDetails = loadable(() =>
  pMinDelay(import('@pages/ClientTariff/DetailsLayout'), 200)
);

const Cases = loadable(() => pMinDelay(import('@pages/Case'), 200));
const CaseDetails = loadable(() =>
  pMinDelay(import('@pages/Case/DetailsLayout'), 200)
);

const FAQ = loadable(() => pMinDelay(import('@pages/FAQ'), 200));

const UserGuide = loadable(() => pMinDelay(import('@pages/UserGuide'), 200));

const adminRoutes: IAdminRoute[] = [
  {
    key: 'Dashboard',
    code: UIMenuCode.Dashboard,
    path: '/dashboard',
    icon: 'Dashboard',
    component: Dashboard,
    layout: '/admin',
    exact: true,
    level: 1
  },
  {
    key: 'Clients',
    code: UIMenuCode.Clients,
    path: '/clients',
    icon: 'Group',
    component: Clients,
    layout: '/admin',
    exact: true,
    level: 1
  },
  {
    key: 'Transport Jobs',
    code: UIMenuCode.TransportJobs,
    path: '/transport-jobs',
    icon: 'LocalShipping',
    component: TransportJobs,
    layout: '/admin',
    exact: true,
    level: 1
  },
  {
    key: 'Operation',
    code: UIMenuCode.Operation,
    path: '/operation',
    icon: 'LocationOn',
    component: Schedule,
    layout: '/admin',
    exact: true,
    level: 1
  },
  {
    key: 'Tariffs and Rates',
    code: [
      UIMenuCode.ClientRates,
      UIMenuCode.Quotations,
      UIMenuCode.ClientTariffs,
      UIMenuCode.CompanyTariffs
    ],
    icon: 'AttachMoney',
    collapse: true,
    state: 'tariffsCollapse',
    level: 1,
    views: [
      {
        key: 'Client Rates',
        code: UIMenuCode.ClientRates,
        path: '/client-rates',
        icon: 'Gavel',
        component: ClientRates,
        layout: '/admin',
        exact: true
      },
      {
        key: 'Quotations',
        code: UIMenuCode.Quotations,
        path: '/quotations',
        icon: 'LocalAtm',
        component: Quotation,
        layout: '/admin',
        exact: true
      },
      {
        key: 'Client Tariffs',
        code: UIMenuCode.ClientTariffs,
        path: '/client-tariffs',
        icon: 'ListAlt',
        component: ClientTariff,
        layout: '/admin',
        exact: true
      },
      {
        key: 'Company Tariffs',
        code: UIMenuCode.CompanyTariffs,
        path: '/company-tariffs',
        icon: 'List',
        component: CompanyTariff,
        layout: '/admin',
        exact: true
      }
    ]
  },
  {
    key: 'Accounting',
    code: UIMenuCode.Accounting,
    path: '/accounting',
    icon: 'Receipt',
    component: Accounting,
    layout: '/admin',
    exact: true,
    level: 1
    // views: [
    //   {
    //     key: 'Invoices',
    //     code: UIMenuCode.Invoices,
    //     path: '/invoices',
    //     icon: 'Payment',
    //     component: Invoices,
    //     layout: '/admin',
    //     exact: true
    //   },
    //   {
    //     key: 'Statements',
    //     code: UIMenuCode.Statements,
    //     path: '/statements',
    //     icon: 'AccountBalance',
    //     component: Statements,
    //     layout: '/admin',
    //     exact: true
    //   }
    // ]
  },
  {
    key: 'Reports',
    code: [
      UIMenuCode.ReportOperations
      // UIMenuCode.ReportAccounting,
      // UIMenuCode.ReportSales
    ],
    icon: 'Timeline',
    collapse: true,
    state: 'reportsCollapse',
    level: 1,
    views: [
      {
        key: 'Operations',
        code: UIMenuCode.ReportOperations,
        path: '/report-operations',
        icon: 'ThreeSixty',
        component: ReportOperations,
        layout: '/admin',
        exact: true
      }
      // {
      //   key: 'Accounting',
      //   code: UIMenuCode.ReportAccounting,
      //   path: '/report-accounting',
      //   icon: 'AssessmentOutlined',
      //   component: ReportAccounting,
      //   layout: '/admin',
      //   exact: true
      // },
      // {
      //   key: 'Sales',
      //   code: UIMenuCode.ReportSales,
      //   path: '/sales',
      //   icon: 'TrendingUp',
      //   component: Billing,
      //   layout: '/admin',
      //   exact: true
      // }
    ]
  },
  {
    key: 'Workshop',
    code: [
      UIMenuCode.Equipment,
      UIMenuCode.FaultyReport,
      UIMenuCode.Maintenance
    ],
    icon: 'HomeWork',
    collapse: true,
    state: 'workshopCollapse',
    level: 1,
    views: [
      {
        key: 'Equipment',
        code: UIMenuCode.Equipment,
        path: '/equipment',
        icon: 'EmojiTransportation',
        component: Equipment,
        layout: '/admin',
        exact: true
      },
      {
        key: 'Faulty Report',
        code: UIMenuCode.FaultyReport,
        path: '/faulty-report',
        icon: 'Report',
        component: FaultyReport,
        layout: '/admin',
        exact: true
      },
      {
        key: 'Maintenance',
        code: UIMenuCode.Maintenance,
        path: '/maintenance',
        icon: 'Build',
        component: Maintenance,
        layout: '/admin',
        exact: true
      }
    ]
  },
  {
    key: 'Administration',
    code: [UIMenuCode.Staff, UIMenuCode.Users, UIMenuCode.Roles],
    icon: 'VpnKey',
    collapse: true,
    state: 'administrationCollapse',
    level: 1,
    views: [
      {
        key: 'Staff',
        code: UIMenuCode.Staff,
        path: '/staff',
        icon: 'GroupAdd',
        component: Staff,
        layout: '/admin',
        exact: true
      },
      {
        key: 'Users',
        code: UIMenuCode.Users,
        path: '/users',
        icon: 'GroupAdd',
        component: Users,
        layout: '/admin',
        exact: true
      },
      {
        key: 'Roles',
        code: UIMenuCode.Roles,
        path: '/roles',
        icon: 'Security',
        component: Roles,
        layout: '/admin',
        exact: true
      }
    ]
  },
  {
    key: 'System Settings',
    code: [
      UIMenuCode.EmailLogs,
      UIMenuCode.Library,
      UIMenuCode.Miscellaneous,
      UIMenuCode.SystemLogs,
      UIMenuCode.ApiIntegration
    ],
    icon: 'Settings',
    collapse: true,
    state: 'settingsCollapse',
    level: 1,
    views: [
      {
        key: 'Email Logs',
        code: UIMenuCode.EmailLogs,
        path: '/email',
        icon: 'Email',
        component: EmailLogs,
        layout: '/admin',
        exact: true
      },
      {
        key: 'Library',
        code: UIMenuCode.Library,
        path: '/library',
        icon: 'Work',
        component: Library,
        layout: '/admin',
        exact: true
      },
      {
        key: 'Miscellaneous',
        code: UIMenuCode.Miscellaneous,
        path: '/miscellaneous',
        icon: 'Grain',
        component: Billing,
        layout: '/admin',
        exact: true
      },
      {
        key: 'System Logs',
        code: UIMenuCode.SystemLogs,
        path: '/logs',
        icon: 'History',
        component: SystemLogs,
        layout: '/admin',
        exact: true
      },
      {
        key: 'API Integration',
        code: UIMenuCode.ApiIntegration,
        path: '/api-integration',
        icon: 'SettingsEthernet',
        component: ApiIntegration,
        layout: '/admin',
        exact: true
      }
    ]
  },
  {
    key: 'Help and Support',
    code: [UIMenuCode.Cases, UIMenuCode.UserGuide, UIMenuCode.Faq],
    icon: 'HeadsetMic',
    collapse: true,
    state: 'helpSupport',
    level: 1,
    views: [
      {
        key: 'Cases',
        code: UIMenuCode.Cases,
        path: '/cases',
        icon: 'ConfirmationNumberOutlined',
        component: Cases,
        layout: '/admin',
        exact: true
      },
      // {
      //   key: 'User Guide',
      //   code: UIMenuCode.UserGuide,
      //   path: '/user-guide',
      //   icon: 'MenuBook',
      //   component: UserGuide,
      //   layout: '/admin',
      //   exact: true
      // },
      {
        key: 'FAQ',
        code: UIMenuCode.Faq,
        path: '/faq',
        icon: 'HelpOutline',
        component: FAQ,
        layout: '/admin',
        exact: true
      }
    ]
  },

  {
    key: 'Clients',
    code: UIMenuCode.ClientDetails,
    path: '/clients/new',
    component: ClientDetails,
    layout: '/admin',
    exact: true,
    other: true
  },
  {
    key: 'Clients',
    code: UIMenuCode.ClientDetails,
    path: '/clients/:id',
    component: ClientDetails,
    layout: '/admin',
    other: true
  },

  {
    key: 'Company Tariffs',
    code: UIMenuCode.CompanyTariffDetails,
    path: '/company-tariffs/new',
    component: CompanyTariffDetails,
    layout: '/admin',
    exact: true,
    other: true
  },
  {
    key: 'Company Tariffs',
    code: UIMenuCode.CompanyTariffDetails,
    path: '/company-tariffs/:chargeCodeId',
    component: CompanyTariffDetails,
    layout: '/admin',
    other: true
  },

  {
    key: 'Client Tariffs',
    code: UIMenuCode.ClientTariffDetails,
    path: '/client-tariffs/new',
    component: ClientTariffDetails,
    layout: '/admin',
    exact: true,
    other: true
  },
  {
    key: 'Client Tariffs',
    code: UIMenuCode.ClientTariffDetails,
    path: '/client-tariffs/:id',
    component: ClientTariffDetails,
    layout: '/admin',
    other: true
  },

  {
    key: 'Quotations',
    code: UIMenuCode.QuotationDetails,
    path: '/quotations/new',
    component: QuotationDetails,
    layout: '/admin',
    exact: true,
    other: true
  },
  {
    key: 'Quotations',
    code: UIMenuCode.QuotationDetails,
    path: '/quotations/:id',
    component: QuotationDetails,
    layout: '/admin',
    other: true
  },

  {
    key: 'Client Rates',
    code: UIMenuCode.ClientRateDetails,
    path: '/client-rates/new',
    component: ClientRateDetails,
    layout: '/admin',
    exact: true,
    other: true
  },
  {
    key: 'Client Rates',
    code: UIMenuCode.ClientRateDetails,
    path: '/client-rates/:id',
    component: ClientRateDetails,
    layout: '/admin',
    other: true
  },

  {
    key: 'Transport Jobs',
    code: UIMenuCode.TransportJobDetails,
    path: '/transport-jobs/new',
    component: TransportJobDetails,
    layout: '/admin',
    exact: true,
    other: true
  },
  {
    key: 'Transport Jobs',
    code: UIMenuCode.TransportJobDetails,
    path: '/transport-jobs/:id',
    component: TransportJobDetails,
    layout: '/admin',
    other: true
  },

  {
    key: 'Statements',
    code: UIMenuCode.StatementDetails,
    path: '/statements/:clientId',
    component: StatementDetails,
    layout: '/admin',
    other: true
  },

  {
    key: 'Users',
    code: UIMenuCode.UserDetails,
    path: '/users/new',
    component: UserDetails,
    layout: '/admin',
    exact: true,
    other: true
  },
  {
    key: 'Users',
    code: UIMenuCode.UserDetails,
    path: '/users/:id',
    component: UserDetails,
    layout: '/admin',
    other: true
  },

  {
    key: 'Staff',
    code: UIMenuCode.StaffDetails,
    path: '/staff/new',
    component: StaffDetails,
    layout: '/admin',
    exact: true,
    other: true
  },
  {
    key: 'Staff',
    code: UIMenuCode.StaffDetails,
    path: '/staff/:id',
    component: StaffDetails,
    layout: '/admin',
    other: true
  },

  {
    key: 'Equipment',
    code: UIMenuCode.EquipmentDetails,
    path: '/equipment/new',
    component: EquipmentDetails,
    layout: '/admin',
    exact: true,
    other: true
  },
  {
    key: 'Equipment',
    code: UIMenuCode.EquipmentDetails,
    path: '/equipment/:id',
    component: EquipmentDetails,
    layout: '/admin',
    other: true
  },

  {
    key: 'Schedules',
    code: UIMenuCode.ScheduleDetails,
    path: '/operation/new',
    component: ScheduleDetails,
    layout: '/admin',
    exact: true,
    other: true
  },
  {
    key: 'Schedules',
    code: UIMenuCode.ScheduleDetails,
    path: '/operation/:id',
    component: ScheduleDetails,
    layout: '/admin',
    other: true
  },

  {
    key: 'CompanyProfile',
    code: UIMenuCode.CompanyProfileDetails,
    path: '/company-profile',
    component: CompanyProfile,
    layout: '/admin',
    other: true
  },

  {
    key: 'Cases',
    code: UIMenuCode.CaseDetails,
    path: '/cases/:id',
    component: CaseDetails,
    layout: '/admin',
    other: true
  }
];

export default adminRoutes;
