import constants, {
  DATE_FORMAT_SERVER,
  FullEmptyServiceType,
  OverMassType,
  ParentSource,
  UITab,
  WebTable
} from '@constants';
import { onShowNote } from '@containers/Note/helpers';
import { Prototype } from '@core';
import {
  useAlertMutationEnhancer,
  useCUDConnectionTrip,
  useCUDSchedule,
  useCUDScheduleContainer,
  useFetchScheduleHasDamagedEquipment
} from '@hooks';
import loadable from '@loadable/component';
import { onShowTransportJob } from '@pages/TransportJob/helpers';
import { UIUtils } from '@utils';
import React, { useCallback, useMemo } from 'react';
import trans from 'translation';
import { KButton, KColors, KContainer, KLabel } from 'uikit';

import DamagedEquipment from './components/DamagedEquipment';

const EditDocuments = loadable(
  () => import('./Schedule/components/EditDocuments')
);
const EditAdditionalDocuments = loadable(
  () => import('./Schedule/components/EditAdditionalDocuments')
);
const EditDriverInstructions = loadable(
  () => import('./Schedule/components/EditDriverInstructions')
);
const EditContacts = loadable(
  () => import('./Schedule/components/EditContacts')
);
const SetOverMass = loadable(() => import('./Schedule/components/SetOverMass'));
const EditPlan = loadable(() => import('./Schedule/components/EditPlan'));
const AddConnectionTrip = loadable(
  () => import('./Schedule/components/AddConnectionTrip')
);

interface IScheduleActionsParams {
  ref: any;
  item: any;
  scheduleId: string | number;
  scheduleDate?: string;
  overmassType: OverMassType | null;
  isLocked?: boolean;
  isSolvedReport?: any;
}

interface IScheduleActionsResult {
  onPress: () => void;
  onEditDocuments: () => void;
  onEditContacts: () => void;
  onEditNotes: () => void;
}

interface IConnectionActionsParams {
  ref: any;
  item: any;
  scheduleId: string | number;
  isLocked?: boolean;
  isSolvedReport?: any;
}

interface IConnectionActionsResult {
  onPress: () => void;
}

interface IAddScheduleConnectionParams {
  scheduleId?: string | number;
  scheduleDate?: string;
}

interface ICheckExceedWeightParams {
  container?: any;
  truck?: any;
  trailer?: any;
  fullEmptyType?: FullEmptyServiceType;
}

export const onViewScheduleContainer = ({ item, scheduleDate }: any) => {
  UIUtils.popup.open({
    title: trans('view_container_schedule'),
    maxWidth: 'md',
    content: () => (
      <EditPlan item={item} isView={true} scheduleDate={scheduleDate} />
    )
  });
};

export const useScheduleContainerActions = (
  options: IScheduleActionsParams
): IScheduleActionsResult => {
  const {
    ref,
    item,
    scheduleId,
    overmassType,
    isLocked,
    isSolvedReport,
    scheduleDate
  } = options;

  const { title, routingStart: start, routingEnd: end, scheduleTripId } = item;

  const routingTypes = useMemo(
    () => [start?.routingType, end?.routingType],
    [end?.routingType, start?.routingType]
  );

  const { deleteMutation } = useCUDScheduleContainer();

  const { onAlert } = useAlertMutationEnhancer({
    mutation: deleteMutation
  } as any);

  const onEditDocuments = useCallback(() => {
    UIUtils.popup.open({
      title: trans('documents') + ` ${title}`,
      content: () => (
        <EditDocuments
          item={item}
          scheduleId={scheduleId}
          routingTypes={routingTypes}
          isView={isLocked}
        />
      )
    });
  }, [isLocked, item, routingTypes, scheduleId, title]);

  const onEditAdditionalDocuments = useCallback(() => {
    UIUtils.popup.open({
      title: trans('additional_documents') + ` ${title}`,
      maxWidth: 'md',
      content: () => (
        <EditAdditionalDocuments
          item={item}
          routingTypes={routingTypes}
          isView={isLocked}
        />
      )
    });
  }, [isLocked, item, routingTypes, title]);

  const onEditDriverInstructions = useCallback(() => {
    UIUtils.popup.open({
      title: trans('driver_instructions') + ` ${title}`,
      content: () => <EditDriverInstructions item={item} isView={isLocked} />
    });
  }, [isLocked, item, title]);

  const onEditContacts = useCallback(() => {
    UIUtils.popup.open({
      title: trans('contacts') + ` ${title}`,
      content: () => <EditContacts item={item} isView={isLocked} />
    });
  }, [isLocked, item, title]);

  const onEditOverMass = useCallback(() => {
    UIUtils.popup.open({
      title: trans('overmass_confirmation'),
      content: () => (
        <SetOverMass
          item={{ id: scheduleTripId, overmassType }}
          isView={isLocked}
        />
      )
    });
  }, [isLocked, overmassType, scheduleTripId]);

  const onEditPlan = useCallback(() => {
    UIUtils.popup.open({
      title: trans('edit_container_schedule'),
      maxWidth: 'md',
      content: () => (
        <EditPlan item={item} isView={isLocked} scheduleDate={scheduleDate} />
      )
    });
  }, [isLocked, item, scheduleDate]);

  const onDeletePlan = useCallback(() => {
    onAlert({ id: item.id });
  }, [item.id, onAlert]);

  const onAddConnectionTrip = useCallback(() => {
    UIUtils.popup.open({
      title: trans('add_connection_trip'),
      maxWidth: 'md',
      content: () => (
        <AddConnectionTrip
          containerId={item.id}
          scheduleId={item.scheduleId}
          isView={isLocked}
        />
      )
    });
  }, [isLocked, item.id, item.scheduleId]);

  const onSendEmail = useCallback(() => {
    if (item?.transportJobId) {
      onShowTransportJob(item.transportJobId, {
        tab: UITab.TransportJobEmails
      });
    }
  }, [item?.transportJobId]);

  const onAddNote = useCallback(() => {
    const mParams = {
      parentId: item.scheduleId,
      source: ParentSource.Schedule,
      webTable: WebTable.SCHEDULE_NOTE,
      otherParams: {
        sourceType: {
          code: constants.SOURCE_TYPE.SCHEDULE_TRIP_CONTAINER,
          name: 'Trip container'
        },
        source: {
          id: item.id,
          code: item.containerNumber,
          name: item.containerNumber
        }
      }
    };

    onShowNote(mParams);
  }, [item]);

  const renderMenu = useCallback(() => {
    const onPressWrapper = (onPress?: () => void) => {
      UIUtils.popper.dismiss();
      onPress?.();
    };

    const btns = [
      {
        title: trans('edit_documents'),
        onPress: () => onPressWrapper(onEditDocuments)
      },
      {
        title: trans('edit_additional_documents'),
        onPress: () => onPressWrapper(onEditAdditionalDocuments)
      },
      {
        title: trans('edit_driver_instructions'),
        onPress: () => onPressWrapper(onEditDriverInstructions)
      },
      {
        title: trans('edit_contacts'),
        onPress: () => onPressWrapper(onEditContacts)
      },
      {
        title: trans('edit_over_mass'),
        onPress: () => onPressWrapper(onEditOverMass),
        disabled: isLocked
      },
      {
        title: trans('edit_schedule'),
        onPress: () => onPressWrapper(onEditPlan),
        disabled: !Prototype.core.isNullOrUndefined(isSolvedReport)
      },
      {
        title: trans('delete_schedule'),
        onPress: () => onPressWrapper(onDeletePlan),
        disabled: isLocked
      },
      {
        title: trans('add_connection_trip'),
        onPress: () => onPressWrapper(onAddConnectionTrip),
        disabled: isLocked
      },
      {
        title: trans('add_note'),
        onPress: () => onPressWrapper(onAddNote)
      },
      {
        title: trans('send_email'),
        onPress: () => onPressWrapper(onSendEmail)
      }
    ];

    return (
      <>
        {btns.map((i, idx) => {
          const { title: _title, ...rest } = i;
          return (
            <KContainer.Touchable
              key={_title}
              {...rest}
              marginT={idx !== 0 ? '0.5rem' : 0}
              cursor={rest.disabled ? 'auto' : 'pointer'}
            >
              <KLabel.Text
                color={rest.disabled ? KColors.palette.gray.w100 : undefined}
              >
                {_title}
              </KLabel.Text>
            </KContainer.Touchable>
          );
        })}
      </>
    );
  }, [
    isLocked,
    isSolvedReport,
    onAddConnectionTrip,
    onAddNote,
    onDeletePlan,
    onEditAdditionalDocuments,
    onEditContacts,
    onEditDocuments,
    onEditDriverInstructions,
    onEditOverMass,
    onEditPlan,
    onSendEmail
  ]);

  const onPress = () =>
    UIUtils.popper.open({
      anchorEl: ref.current,
      touchOutsideToDismiss: true,
      placement: 'right-end',
      padding: '0.75rem',
      withMaxZIndex: true,
      content: renderMenu
    });

  return {
    onPress,
    onEditDocuments,
    onEditContacts,
    onEditNotes: onEditDriverInstructions
  };
};

export const useAddConnectionTrip = (options: IAddScheduleConnectionParams) => {
  const onAdd = useCallback(() => {
    UIUtils.popup.open({
      title: trans('add_connection_trip'),
      maxWidth: 'md',
      content: () => <AddConnectionTrip {...options} />
    });
  }, [options]);

  return onAdd;
};

export const useScheduleConnectionActions = (
  options: IConnectionActionsParams
): IConnectionActionsResult => {
  const { ref, item, scheduleId, isLocked, isSolvedReport } = options;

  const { deleteMutation } = useCUDConnectionTrip();

  const { onAlert } = useAlertMutationEnhancer({
    mutation: deleteMutation
  } as any);

  const onEditTrip = useCallback(() => {
    UIUtils.popup.open({
      title: trans('edit_connection_trip'),
      maxWidth: 'md',
      content: () => (
        <AddConnectionTrip
          isView={isLocked}
          id={item.id}
          scheduleId={scheduleId}
        />
      )
    });
  }, [isLocked, item.id, scheduleId]);

  const onDeleteTrip = useCallback(() => {
    onAlert({ id: item.id });
  }, [item.id, onAlert]);

  const onAddConnectionTrip = useCallback(() => {
    UIUtils.popup.open({
      title: trans('add_connection_trip'),
      maxWidth: 'md',
      content: () => (
        <AddConnectionTrip
          scheduleId={scheduleId}
          isView={isLocked}
          connectionId={item.id}
        />
      )
    });
  }, [isLocked, item.id, scheduleId]);

  const renderMenu = useCallback(() => {
    const onPressWrapper = (onPress?: () => void) => {
      UIUtils.popper.dismiss();
      onPress?.();
    };

    const btns = [
      {
        title: trans('edit_trip'),
        onPress: () => onPressWrapper(onEditTrip),
        disabled: !Prototype.core.isNullOrUndefined(isSolvedReport)
      },
      {
        title: trans('delete_trip'),
        onPress: () => onPressWrapper(onDeleteTrip),
        disabled: isLocked
      },
      {
        title: trans('add_connection_trip'),
        onPress: () => onPressWrapper(onAddConnectionTrip),
        disabled: isLocked
      }
    ];

    return (
      <>
        {btns.map((i, idx) => {
          const { title, ...rest } = i;
          return (
            <KContainer.Touchable
              key={title}
              {...rest}
              marginT={idx !== 0 ? '0.5rem' : 0}
              cursor={rest.disabled ? 'auto' : 'pointer'}
            >
              <KLabel.Text
                color={rest.disabled ? KColors.palette.gray.w100 : undefined}
              >
                {title}
              </KLabel.Text>
            </KContainer.Touchable>
          );
        })}
      </>
    );
  }, [isLocked, isSolvedReport, onAddConnectionTrip, onDeleteTrip, onEditTrip]);

  const onPress = () =>
    UIUtils.popper.open({
      anchorEl: ref.current,
      touchOutsideToDismiss: true,
      placement: 'right-end',
      padding: '0.75rem',
      withMaxZIndex: true,
      content: renderMenu
    });

  return { onPress };
};

export const useScheduleFilterColumns = () => {
  const cb = useCallback(() => {
    return [
      {
        label: trans('driver_code'),
        name: 'driverCode'
      },
      {
        label: trans('driver_name'),
        name: 'driverName'
      },
      {
        label: trans('truck_code'),
        name: 'truckCode'
      },
      {
        label: trans('truck_name'),
        name: 'truckName'
      },
      {
        label: trans('trailer_code'),
        name: 'trailerCode'
      },
      {
        label: trans('trailer_name'),
        name: 'trailerName'
      },
      {
        label: trans('job_type'),
        name: 'jobType'
      },
      {
        label: trans('drop_mode'),
        name: 'dropMode'
      },
      {
        label: trans('container_size'),
        name: 'containerSize'
      },
      {
        label: trans('connection_trip_no_toll'),
        name: 'connectionTripNoToll'
      },
      {
        label: trans('schedule_trip_no_toll'),
        name: 'scheduleTripNoToll'
      },
      {
        label: trans('time_start'),
        name: 'timeStart'
      },
      {
        label: trans('time_end'),
        name: 'timeEnd'
      },
      {
        label: trans('full_empty_type'),
        name: 'fullEmptyType'
      }
    ];
  }, []);

  return cb;
};

export const useDeleteSchedule = () => {
  const { deleteMutation } = useCUDSchedule();

  return useAlertMutationEnhancer({ mutation: deleteMutation });
};

export const useScheduleDamagedEquipment = (from: string, to?: string) => {
  const { isNowOrFuture, d } = useMemo(() => {
    let _isNowOrFuture =
      Prototype.date
        .toMoment(from)
        ?.isSameOrAfter(Prototype.date.now(), 'days') ?? false;
    let _d = from;

    if (to) {
      const _tIsNowOrFuture =
        Prototype.date
          .toMoment(to)
          ?.isSameOrAfter(Prototype.date.now(), 'days') ?? false;
      _d = !_isNowOrFuture
        ? _tIsNowOrFuture
          ? Prototype.date.now().format(DATE_FORMAT_SERVER)
          : _d
        : _d;
      _isNowOrFuture = _isNowOrFuture || _tIsNowOrFuture;
    }

    return { isNowOrFuture: _isNowOrFuture, d: _d };
  }, [from, to]);

  const {
    data: hasDamagedEquipment,
    refetch,
    isLoading
  } = useFetchScheduleHasDamagedEquipment(d, undefined, isNowOrFuture);

  const onShowDamagedEquipmentList = useCallback(() => {
    UIUtils.popup.open({
      title: trans('equipment_list_in_damaged_or_repair'),
      maxWidth: 'lg',
      content: () => <DamagedEquipment from={d} />
    });
  }, [d]);

  const renderDamagedBtn = useMemo(() => {
    if (hasDamagedEquipment) {
      return (
        <KButton.Icon
          icon="PriorityHigh"
          tintColor={KColors.secondary.dark}
          size="lg"
          marginL="0.25rem"
          onPress={() => onShowDamagedEquipmentList()}
          disabled={isLoading}
        />
      );
    }

    return undefined;
  }, [hasDamagedEquipment, isLoading, onShowDamagedEquipmentList]);

  return {
    hasDamagedEquipment,
    onShowDamagedEquipmentList,
    refetchDamagedEquipment: refetch,
    isLoadingDamagedEquipment: isLoading,
    validDate: d,
    renderDamagedBtn
  };
};

export const useCheckExceedWeight = (options: ICheckExceedWeightParams) => {
  const { container, truck, trailer, fullEmptyType } = options;

  if (!container || !truck || !trailer) {
    return false;
  }

  const containerWeight =
    (fullEmptyType === FullEmptyServiceType.Empty
      ? container.tare
      : container.grossWeight) ?? 0;

  const truckMaxWeight = [
    truck.massLevel2TotalWeight,
    truck.massLevel1TotalWeight,
    truck.standardTotalWeight,
    truck.maxTotalWeight
  ].filter(i => i)[0];

  const truckWeight = truck.equipmentTareWeight ?? 0;

  const trailerWeight = !!trailer.subType?.isCombination
    ? (trailer.subEquipments ?? []).reduce((acc: any, cur: any) => {
        return acc + (cur.equipmentTareWeight ?? 0);
      }, 0)
    : trailer.equipmentTareWeight ?? 0;

  return (
    truckMaxWeight > 0 &&
    containerWeight + truckWeight + trailerWeight > truckMaxWeight
  );
};
