import {
  ContainerInvoiceStatus,
  FaultyReportStatus,
  ArInvoiceType,
  ReportType,
  SyncStatus,
  TripRoutingStatus,
  ApInvoiceType,
  InvoiceType,
  FullEmptyServiceType
} from '@constants';
import trans from 'translation';
import { KColors } from 'uikit';

export const mappedContainerInvoiceStatus = () => ({
  [ContainerInvoiceStatus.DraftInvoice]: {
    color: KColors.blue.normal
  },
  [ContainerInvoiceStatus.Invoiced]: {
    color: KColors.warning.normal
  },
  [ContainerInvoiceStatus.Paid]: {
    color: KColors.primary.normal
  },
  [ContainerInvoiceStatus.PartiallyPaid]: {
    color: KColors.primary.normal
  }
});

export const mappedInvoiceType = () => ({
  [ArInvoiceType.ArInvoice]: {
    color: KColors.primary.normal,
    label: InvoiceType.Invoice
  },
  [ArInvoiceType.ArCreditNote]: {
    color: KColors.secondary.normal,
    label: InvoiceType.CreditNote
  },
  [ArInvoiceType.ArDisbursement]: {
    color: KColors.primary.normal,
    label: InvoiceType.Disbursement
  },

  [ApInvoiceType.ApInvoice]: {
    color: KColors.primary.normal,
    label: InvoiceType.Invoice
  },
  [ApInvoiceType.ApCreditNote]: {
    color: KColors.secondary.normal,
    label: InvoiceType.CreditNote
  },
  [ApInvoiceType.ApDisbursement]: {
    color: KColors.primary.normal,
    label: InvoiceType.Disbursement
  }
});

export const mappedSyncStatus = () => ({
  [SyncStatus.Success]: {
    color: KColors.primary.normal
  },
  [SyncStatus.Failed]: {
    color: KColors.secondary.normal
  }
});

export const mappedFaultyReportStatus = () => ({
  [FaultyReportStatus.ToDo]: {
    color: KColors.secondary.normal,
    brC: KColors.secondary.normal,
    background: KColors.palette.secondary.w50
  },
  [FaultyReportStatus.InProgress]: {
    color: KColors.warning.normal,
    brC: KColors.warning.normal,
    background: KColors.palette.warning.w50
  },
  [FaultyReportStatus.Cancelled]: {
    color: KColors.gray.normal,
    brC: KColors.gray.normal,
    background: KColors.palette.gray.w50
  },
  [FaultyReportStatus.Done]: {
    color: KColors.primary.normal,
    brC: KColors.primary.normal,
    background: KColors.palette.primary.w50
  }
});

export const mappedReportType = () => ({
  [ReportType.Template]: {
    color: KColors.secondary.normal,
    brC: KColors.secondary.normal,
    background: KColors.palette.secondary.w50
  },
  [ReportType.LeaveOfTrip]: {
    color: KColors.blue.normal,
    brC: KColors.blue.normal,
    background: KColors.palette.blue.w50
  },
  [ReportType.PdfCustomized]: {
    color: KColors.warning.normal,
    brC: KColors.warning.normal,
    background: KColors.palette.warning.w50
  },
  [ReportType.Faulty]: {
    color: KColors.primary.normal,
    brC: KColors.primary.normal,
    background: KColors.palette.primary.w50
  }
});

export const mappedTripRoutingStatus = (): Record<string, any> => ({
  [TripRoutingStatus.Booked]: {
    color: KColors.blue.normal,
    label: trans(`option.${TripRoutingStatus.Booked.toLowerCase()}`)
  },
  [TripRoutingStatus.ReadySchedule]: {
    color: KColors.secondary.normal,
    label: trans(`option.${TripRoutingStatus.ReadySchedule.toLowerCase()}`)
  },
  [TripRoutingStatus.Scheduled]: {
    color: KColors.primary.normal,
    label: trans(`option.${TripRoutingStatus.Scheduled.toLowerCase()}`)
  },
  [TripRoutingStatus.InTransit]: {
    color: '#28A745',
    label: trans(`option.${TripRoutingStatus.InTransit.toLowerCase()}`)
  },
  [TripRoutingStatus.Completed]: {
    color: KColors.secondary.normal,
    label: trans(`option.${TripRoutingStatus.Completed.toLowerCase()}`)
  },
  [TripRoutingStatus.Cancelled]: {
    color: KColors.secondary.dark,
    label: trans(`option.${TripRoutingStatus.Cancelled.toLowerCase()}`)
  },
  [TripRoutingStatus.CancelledByDriver]: {
    color: KColors.primary.normal,
    label: trans(`option.${TripRoutingStatus.CancelledByDriver.toLowerCase()}`)
  },
  [TripRoutingStatus.InCompleted]: {
    color: KColors.secondary.dark,
    label: trans(`option.${TripRoutingStatus.InCompleted.toLowerCase()}`)
  },
  [TripRoutingStatus.UnCompleted]: {
    color: KColors.secondary.dark,
    label: trans(`option.${TripRoutingStatus.UnCompleted.toLowerCase()}`)
  }
});

export const mappedFullEmptyType = () => ({
  [FullEmptyServiceType.Full]: {
    color: KColors.primary.normal,
    label: trans(`option.${FullEmptyServiceType.Full.toLowerCase()}`)
  },
  [FullEmptyServiceType.Empty]: {
    color: KColors.warning.normal,
    label: trans(`option.${FullEmptyServiceType.Empty.toLowerCase()}`),
    brC: KColors.warning.normal
  }
});
