/* eslint-disable max-lines */
import { OperandTypes, OperatorData, Operators, YesOrNo } from '@constants';
import { IAdvanceSearch, ISearchCondition } from '@dto';
import { useResolverForm } from '@hooks';
import { ValidationUtils } from '@utils';
import { startCase } from 'lodash';
import React, { memo, useCallback } from 'react';
import { Controller, useFieldArray, useWatch } from 'react-hook-form';
import trans from 'translation';
import {
  KButton,
  KColors,
  KContainer,
  KGrid,
  KInput,
  KLabel,
  KListItem,
  KPicker,
  KForm,
  KCardProps
} from 'uikit';
import * as yup from 'yup';

import { normalizeFilterData } from './helpers';

interface IProps {
  dismiss?: () => void;
  advanceSearch: IAdvanceSearch[];
  searchConditions: ISearchCondition[];
  onSubmit?: (params: ISearchCondition[]) => void;
  title?: string;
  wrapperType?: 'view' | 'card';
  wrapperProps?: KCardProps;
  renderHeader?: JSX.Element;
  headerBelow?: boolean;
}

interface IFormData {
  searchConditions: any[];
}

const key = 'advanced_search';

const INIT_ITEM = {
  fieldName: '',
  operandType: '',
  operatorType: '',
  data: '',
  min: '',
  max: '',
  minDate: null,
  maxDate: null
};

const schema = yup.object().shape({
  searchConditions: yup.array().of(
    yup.object().shape({
      fieldName: ValidationUtils.required(),
      operatorType: ValidationUtils.required(),
      data: yup
        .mixed()
        .nullable()
        .when(
          ['operatorType', 'operandType'],
          ([operatorType, operandType], _schema) => {
            return [Operators.BETWEEN, Operators.IN].includes(operatorType)
              ? _schema
              : [
                    OperandTypes.DATETIME,
                    OperandTypes.DATE,
                    OperandTypes.TIME
                  ].includes(operandType)
                ? ValidationUtils.requiredDate()
                : _schema.required();
          }
        ),
      minDate: ValidationUtils.nullableDate(),
      maxDate: ValidationUtils.nullableDate()
    })
  )
});

const Filter = ({
  dismiss,
  searchConditions,
  advanceSearch,
  onSubmit,
  title,
  wrapperType = 'view',
  wrapperProps,
  renderHeader,
  headerBelow = false
}: IProps) => {
  const methods = useResolverForm<IFormData>({
    schema,
    configs: {
      defaultValues: {
        searchConditions: searchConditions.map(i => ({
          ...i,
          data:
            i.operandType === OperandTypes.BOOLEAN
              ? i.data
                ? YesOrNo.Yes
                : YesOrNo.No
              : i.data
        }))
      }
    }
  });

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'searchConditions'
  });

  const conditions = useWatch({
    control: methods.control,
    name: 'searchConditions'
  });

  const onFormValid = useCallback(
    (v: IFormData) => {
      const mParams = normalizeFilterData(v.searchConditions);

      onSubmit?.(mParams);
      dismiss?.();
    },
    [dismiss, onSubmit]
  );

  const renderStringInput = useCallback(
    (index: number) => {
      return (
        <KInput.TextField
          {...methods.register(`searchConditions.${index}.data` as const)}
          label={trans(`${key}.data`)}
        />
      );
    },
    [methods]
  );

  const renderBooleanInput = useCallback(
    (index: number) => {
      return (
        <Controller
          control={methods.control}
          render={({ field }) => (
            <KListItem.RadioGroup
              containerStyle={{ marginTop: '0.75rem' }}
              data={[
                {
                  id: YesOrNo.Yes,
                  label: trans('yes').toUpperCase(),
                  checked: field.value === YesOrNo.Yes,
                  onChange: v => {
                    if (v) {
                      methods.setValue(
                        `searchConditions.${index}.data`,
                        YesOrNo.Yes
                      );
                    }
                  }
                },
                {
                  id: YesOrNo.No,
                  label: trans('no').toUpperCase(),
                  checked: field.value === YesOrNo.No,
                  onChange: v => {
                    if (v) {
                      methods.setValue(
                        `searchConditions.${index}.data`,
                        YesOrNo.No
                      );
                    }
                  }
                }
              ]}
              direction="row"
            />
          )}
          name={`searchConditions.${index}.data`}
        />
      );
    },
    [methods]
  );

  const renderDateInput = useCallback(
    (index: number) => {
      const operatorType = conditions?.[index]?.operatorType;

      switch (operatorType) {
        case Operators.BETWEEN:
          return (
            <KGrid.Container>
              <KGrid.Item xs>
                <Controller
                  name={`searchConditions.${index}.minDate`}
                  control={methods.control}
                  render={({ field }) => {
                    return (
                      <KPicker.Date {...field} label={trans(`${key}.from`)} />
                    );
                  }}
                />
              </KGrid.Item>

              <KGrid.Item xs>
                <Controller
                  name={`searchConditions.${index}.maxDate`}
                  control={methods.control}
                  render={({ field }) => {
                    return (
                      <KPicker.Date {...field} label={trans(`${key}.to`)} />
                    );
                  }}
                />
              </KGrid.Item>
            </KGrid.Container>
          );

        case Operators.EQUAL:
        case Operators.GREATER_EQUAL:
        case Operators.LESS_EQUAL:
          return (
            <Controller
              name={`searchConditions.${index}.data`}
              control={methods.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <KPicker.Date
                    {...field}
                    label={trans(`${key}.data`)}
                    message={error?.message}
                  />
                );
              }}
            />
          );

        default:
          return null;
      }
    },
    [conditions, methods.control]
  );

  const renderDateTimeInput = useCallback(
    (index: number) => {
      const operatorType = conditions?.[index]?.operatorType;

      switch (operatorType) {
        case Operators.BETWEEN:
          return (
            <KGrid.Container>
              <KGrid.Item xs>
                <Controller
                  name={`searchConditions.${index}.minDate`}
                  control={methods.control}
                  render={({ field }) => (
                    <KPicker.DateTime {...field} label={trans(`${key}.from`)} />
                  )}
                />
              </KGrid.Item>

              <KGrid.Item xs>
                <Controller
                  name={`searchConditions.${index}.maxDate`}
                  control={methods.control}
                  render={({ field }) => (
                    <KPicker.DateTime {...field} label={trans(`${key}.to`)} />
                  )}
                />
              </KGrid.Item>
            </KGrid.Container>
          );

        case Operators.EQUAL:
        case Operators.GREATER_EQUAL:
        case Operators.LESS_EQUAL:
          return (
            <Controller
              name={`searchConditions.${index}.data`}
              control={methods.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <KPicker.DateTime
                    {...field}
                    label={trans(`${key}.data`)}
                    message={error?.message}
                  />
                );
              }}
            />
          );

        default:
          return null;
      }
    },
    [conditions, methods.control]
  );

  const renderTimeInput = useCallback(
    (index: number) => {
      const operatorType = conditions?.[index]?.operatorType;

      switch (operatorType) {
        case Operators.BETWEEN:
          return (
            <KGrid.Container>
              <KGrid.Item xs>
                <Controller
                  name={`searchConditions.${index}.minDate`}
                  control={methods.control}
                  render={({ field }) => (
                    <KPicker.Time {...field} label={trans(`${key}.from`)} />
                  )}
                />
              </KGrid.Item>

              <KGrid.Item xs>
                <Controller
                  name={`searchConditions.${index}.maxDate`}
                  control={methods.control}
                  render={({ field }) => (
                    <KPicker.Time {...field} label={trans(`${key}.to`)} />
                  )}
                />
              </KGrid.Item>
            </KGrid.Container>
          );

        case Operators.EQUAL:
        case Operators.GREATER_EQUAL:
        case Operators.LESS_EQUAL:
          return (
            <Controller
              name={`searchConditions.${index}.data`}
              control={methods.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <KPicker.Time
                    {...field}
                    label={trans(`${key}.data`)}
                    message={error?.message}
                  />
                );
              }}
            />
          );

        default:
          return null;
      }
    },
    [conditions, methods.control]
  );

  const renderNumberInput = useCallback(
    (index: number) => {
      const operatorType = conditions?.[index]?.operatorType;

      switch (operatorType) {
        case Operators.BETWEEN:
          return (
            <KGrid.Container>
              <KGrid.Item xs>
                <KInput.TextField
                  {...methods.register(
                    `searchConditions.${index}.min` as const
                  )}
                  label={trans('min')}
                  type="number"
                />
              </KGrid.Item>

              <KGrid.Item xs>
                <KInput.TextField
                  {...methods.register(
                    `searchConditions.${index}.max` as const
                  )}
                  label={trans('max')}
                  type="number"
                />
              </KGrid.Item>
            </KGrid.Container>
          );

        case Operators.EQUAL:
        case Operators.GREATER_EQUAL:
        case Operators.LESS_EQUAL:
          return (
            <Controller
              name={`searchConditions.${index}.data`}
              control={methods.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <KInput.TextField
                    {...field}
                    label={trans(`${key}.data`)}
                    type="number"
                    message={error?.message}
                  />
                );
              }}
            />
          );

        default:
          return null;
      }
    },
    [conditions, methods]
  );

  const renderDataInput = useCallback(
    (index: number) => {
      const operandType = conditions?.[index]?.operandType;

      switch (operandType) {
        case OperandTypes.DATE:
          return renderDateInput(index);

        case OperandTypes.DATETIME:
          return renderDateTimeInput(index);

        case OperandTypes.TIME:
          return renderTimeInput(index);

        case OperandTypes.BOOLEAN:
          return renderBooleanInput(index);

        case OperandTypes.INTEGER:
        case OperandTypes.DECIMAL:
          return renderNumberInput(index);

        case OperandTypes.STRING:
        case OperandTypes.REF_ID:
        default:
          return renderStringInput(index);
      }
    },
    [
      conditions,
      renderBooleanInput,
      renderDateInput,
      renderDateTimeInput,
      renderNumberInput,
      renderStringInput,
      renderTimeInput
    ]
  );

  const Wrapper = wrapperType === 'card' ? KContainer.Card : KContainer.View;

  return (
    <Wrapper width={675} background="white" {...wrapperProps}>
      <KForm onSubmit={methods.handleSubmit(onFormValid)}>
        {!headerBelow && renderHeader}

        <KContainer.View row alignItems justifyContent="space-between">
          <KLabel.Text typo="TextMdMedium">
            {title || trans('filter')}
          </KLabel.Text>

          {wrapperType !== 'card' && (
            <KButton.Icon icon="Close" onPress={() => dismiss?.()} />
          )}
        </KContainer.View>

        {headerBelow && renderHeader}

        {fields.map((i, index) => {
          const [fieldName, operandType, operatorType] = methods.watch([
            `searchConditions.${index}.fieldName`,
            `searchConditions.${index}.operandType`,
            `searchConditions.${index}.operatorType`
          ]);

          return (
            <KGrid.Container key={i.id} style={{ marginTop: '0.5rem' }}>
              <KGrid.Item xs={3}>
                <Controller
                  control={methods.control}
                  render={({ field }) => {
                    return (
                      <KInput.TextField
                        select
                        label={trans(`${key}.column`)}
                        options={advanceSearch.map(o => ({
                          key: o.columnName,
                          label: o.label ?? ''
                        }))}
                        {...field}
                        onChange={e => {
                          field.onChange(e);

                          const c = advanceSearch.find(
                            a => a.columnName === e.target.value
                          );
                          if (c) {
                            methods.setValue(
                              `searchConditions.${index}.label`,
                              c.label
                            );
                            methods.setValue(
                              `searchConditions.${index}.operandType`,
                              c.operandType
                            );
                            if (c.operandType !== operandType) {
                              methods.setValue(
                                `searchConditions.${index}.operatorType`,
                                OperatorData[c.operandType][0].id
                              );
                              methods.setValue(
                                `searchConditions.${index}.data`,
                                c.operandType === OperandTypes.BOOLEAN
                                  ? YesOrNo.Yes
                                  : [
                                        OperandTypes.DATE,
                                        OperandTypes.DATETIME,
                                        OperandTypes.TIME
                                      ].includes(c.operandType)
                                    ? null
                                    : ''
                              );
                              methods.setValue(
                                `searchConditions.${index}.min`,
                                ''
                              );
                              methods.setValue(
                                `searchConditions.${index}.max`,
                                ''
                              );
                              methods.setValue(
                                `searchConditions.${index}.minDate`,
                                null
                              );
                              methods.setValue(
                                `searchConditions.${index}.maxDate`,
                                null
                              );
                            }
                          }
                        }}
                      />
                    );
                  }}
                  name={`searchConditions.${index}.fieldName`}
                />
              </KGrid.Item>

              <KGrid.Item xs={3}>
                <Controller
                  control={methods.control}
                  render={({ field }) => {
                    return (
                      <KInput.TextField
                        {...field}
                        label={trans(`${key}.operator`)}
                        select
                        options={OperatorData[
                          operandType || OperandTypes.STRING
                        ].map(o => ({
                          key: o.id,
                          label: startCase(trans(o.name))
                        }))}
                        disabled={!fieldName}
                      />
                    );
                  }}
                  name={`searchConditions.${index}.operatorType`}
                />
              </KGrid.Item>

              <KGrid.Item xs key={`${operandType}-${operatorType}`}>
                {renderDataInput(index)}
              </KGrid.Item>

              <KGrid.Item>
                <KButton.Icon
                  icon="Delete"
                  kind="secondary"
                  onPress={() => remove(index)}
                  // marginT="0.75rem"
                />
              </KGrid.Item>
            </KGrid.Container>
          );
        })}

        <KContainer.View
          row
          alignItems
          justifyContent="space-between"
          marginT="1rem"
        >
          <KButton.Outline
            icon="Add"
            onPress={() => append(INIT_ITEM)}
            title={trans('add_filter')}
            kind="normal"
            brC={KColors.primary.normal}
          />

          <KContainer.View row alignItems>
            <KButton.Solid
              kind="secondary"
              marginR={'0.5rem'}
              onPress={() => methods.setValue('searchConditions', [])}
              title={trans('clear')}
            />

            <KButton.Solid type="submit" title={trans('submit')} />
          </KContainer.View>
        </KContainer.View>
      </KForm>
    </Wrapper>
  );
};

export default memo(Filter);
